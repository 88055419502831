<script>

import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue';

export default {
    extends: CoreComponent,
    data() {
        return {
            path: null, 
            activeTopic: 'all',
            jsonResponse: null,
            articlesHTML: '',
            limit: 6,
            offset: 0,
            endOfTopic: false,
            loadButton: null
        };
    },
    methods: {
        fetchPath() {
           if(document.querySelectorAll(".cmp-article-filter").length > 0) {
            let el = document.querySelector(".cmp-article-filter");
                        
            if(this.path === null) {
                this.path = el.dataset.rootPath; 
            }
            
           }
           
        },
        async fetchArticles(append = false) {

            this.fetchPath();
            
            let articleWrapper = document.querySelector('.cmp-article-filter__grid');

            if (!append) { articleWrapper.innerHTML = ''; }
            try {

                // Limit + 1 to check if more articles exist
                let response = await fetch(
                    `.dow.article.filter.json?articlesPath=${this.path}&limit=${this.limit+1}&offset=${this.offset}&topic=${this.activeTopic}`,
                    {
                        mode: 'no-cors',
                        method: 'GET',
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                this.jsonResponse = await response.json();

                // Subtract 1 from length because of next article check
                let limit = Math.min(this.jsonResponse.length, this.limit);
                for (let i=0; i<limit; i++) {
                    let article = this.jsonResponse[i];
                    
                    let link = document.createElement("a");
                    link.classList.add("article-filter-tile__link");
                    link.href = article.link;
                    link.setAttribute("aria-label", "Read " + article.heading);
                    link.innerHTML = `<div class="article-filter-tile__tile">
                        <div class="article-filter-tile__image" style="background-image:url('${article.thumbnailImage}')">
                        <img src="${article.thumbnailImage}" alt="${article.articleAltText}">
                        </div>	 
                
                        <div class="article-filter-tile__content">
                        <p class="content__label"><span class="label__name">${article.author}</span> | ${article.tags[0]}</p>
                        <h3 class="content__title">${article.heading}</h3>
                        <p class="content__detail">${article.summary}</p>
                        <p class="content__date">${article.articleDate}</p>
                        </div>
                    </div>`;

                    articleWrapper.appendChild(link);

                }

                if (this.jsonResponse.length <= this.limit) {
                    this.hideLoadButton();
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        changeTab(tabId) {
            this.activeTopic = tabId;
            this.offset = 0; 
            this.showLoadButton();
            this.fetchArticles();
        },
        loadMore() {
            this.offset += this.limit;
            this.fetchArticles(true);
            
        },
        hideLoadButton() {
            this.loadButton.style.display = "none";
        },
        showLoadButton() {
            this.loadButton.style.removeProperty("display");
        }
    },
    mounted() {
        let urlParams = new URLSearchParams(window.location.search);

        let filters = document.querySelectorAll(".cmp-article-filter__filter");


        filters.forEach(filter => {
            filter.addEventListener("click", e => {

                let active = document.querySelector('.cmp-article-filter__filter--active');
                if (active) {
                    active.classList.remove('cmp-article-filter__filter--active');
                }

                this.changeTab(filter.dataset.id);
                filter.classList.add('cmp-article-filter__filter--active');
                
                urlParams.set("topic", filter.dataset.id);
                history.pushState(null, null, "?"+urlParams.toString());

            });
        });
         
        // Mobile Dropdown Menu
        document.querySelector("#blog-mobile-dropdown").addEventListener("change", e => {
            this.changeTab(e.target.value);          
            urlParams.set("topic", e.target.value);                
            history.pushState(null, null, "?"+urlParams.toString());
        });

        this.loadButton = document.querySelector(".cmp-article-filter__load-button");
        this.loadButton.addEventListener("click", e => {
            this.loadMore();
            e.preventDefault();
        })

        // Check URL if 'topic' was selected
        if(urlParams.has('topic')) {
            let topic = decodeURIComponent(urlParams.get('topic'));
            this.activeTopic = topic;
        } 

        document.querySelector('button.cmp-article-filter__filter[data-id="'+this.activeTopic+'"]').classList.add('cmp-article-filter__filter--active');
        document.querySelector('#blog-mobile-dropdown').value = this.activeTopic;
        
        document.onreadystatechange = () => {
            if (document.readyState == "complete") {
                this.fetchPath();
                this.fetchArticles();
            }
        }

    }
};

</script>

<style lang="scss">
    @import './articleFilter.scss';
</style>
