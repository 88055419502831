<script>
import PlatformImage from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/image/image.vue'

export default {
    extends: PlatformImage
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/image/image.scss';
@import '../image/image.scss';
</style>
