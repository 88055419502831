<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import store from '@/store'
import { email as emailRule } from '@vee-validate/rules'

export default {
  extends: CoreComponent,
  store: store,
  data() {
    return {
        attachments: [],
        fileAttachFailure: false,
        MB: 1048576,
        formId: 'supplier-invoice-contact'
    }
  },

  mounted: function () {
    $('#supplierSubmitInfo').text(this.getI18nMessageTenant('supplier.submit.info', window.tenant))
    document.querySelectorAll('#supplierLandingDiv .supplier-contact-us__form input,#supplierLandingDiv .supplier-contact-us__form select')
      .forEach(field => {
        field.addEventListener('click', () => this.$store.dispatch('form/trackInteraction', this.cmpId))
        field.addEventListener('focus', () => this.$store.dispatch('form/trackInteraction', this.cmpId))
      })

    //Show bank info section if option 3 (bank info) is pre-selected on reload
    var reqType = $("input[name='reqType']:checked").val()
    if (reqType == "3") {
      $('.form__row__bank').toggle()
      $('.form__row_upload').toggle()
    }

    $('#country').on('change', function () {
      var country = $('#country').val()
      if (country.toLowerCase() === 'nl')
        $('#netherlands-sme-message').addClass('show-message').removeClass('hide-message')
      else
        $('#netherlands-sme-message').addClass('hide-message').removeClass('show-message')
    })


    $('#email').on('focusout', (e) => {
      var email = $("#email").val()
      if (/^[A-Za-z0-9+\'_.-]+@[A-Za-z0-9.-]+\.[a-zA-Z0-9._-]+/gi.test(email) && /@dow.com\s*$/.test(email)) {
        $("#dowEmailDivId").show()
        var serviceNowUrl = $.find("#serviceNowUrl")?.[0]?.textContent?.trim();
        setTimeout(function () { window.location.href = serviceNowUrl; }, 2000)
      }
      else if (emailRule(email)) {
        $("#emailErrorDivId").hide()
      }
      else {
        $("#emailErrorDivId").show()
      }
    })

    $('#phone').on('focusout', function (e) {
      var phone = $("#phone").val()
      var phoneNumberValidateRegex = /(^[(][0-9]+[)][0-9 -]+$)|(^[0-9 -]+$)|(^[+][0-9 -]+$)|(^[(][+][0-9]+[)][0-9 -]+$)/s
      if (phoneNumberValidateRegex.test(phone) && phone.replace(/ |\(|\)|\+|-/g, '').length > 6 && phone.replace(/ |\(|\)|\+|-/g, '').length < 16) {
        $("#phoneNumberInvalid").hide()
      } else {
        $("#phoneNumberInvalid").show()
      }
    })

    // Toggle bank info section if bank option is selected
    $('input[name="reqType"]').bind('change', function () {
      var showOrHide = ($(this).val() == 3) ? true : false
      $('.form__row__bank').toggle(showOrHide)
      $('.form__row_upload').toggle(showOrHide)
    })

    $('.fileBtn').on("click", function (event) {
      $('.fileInput').click()
    })

    $('.fileInput').on('change', this.fileInputChange)
    $(".fileName").on("click", 'svg', this.fileRemove)

    $("#invoiceContactBtn").on("click", this.invoiceContactSubmit) //invoiceContactBtn click method end

    window.recaptchaSuccessCheck = function getResponse() {
      $("#recaptcha-required-error").hide()
    }

  },

  methods: {
    getI18nMessageTenant(label, tenant) {

      var tenantLabel = tenant + '.' + label
      // First try to get a translation with the prefixed tenant
      var result = window.Granite.I18n.get(tenantLabel)

      // And if that doesn't work, fall back to the label without the tenant
      if (result == tenantLabel) {
        result = window.Granite.I18n.get(label)
      }
      return result
    },

    checkFileSize(fileSize) {
      let fileSizesSum = fileSize
      this.attachments.map(file => {
        fileSizesSum += file.size
      })
      if (fileSizesSum > (this.MB * 30)) {
        return true
      } else {
        return false
      }
    },

    checkFileNum(fileLength) {
      if (this.attachments.length + fileLength > 7) {
        return false
      }
      return true
    },

    async getFileAttachment(attachment) {
      if (attachment) {
        return await new Promise(function (resolve, reject) {
          var reader = new FileReader()
          reader.readAsDataURL(attachment)
          reader.onload = function () {
            resolve({
              fileName: attachment.name,
              mimeType: attachment.type,
              size: attachment.size,
              fileData: reader.result.split(',')[1],
            })
          }.bind(this)
          reader.onerror = function () { reject(reader.error) }
        }).catch(function (error) {
          fileAttachFailure = true
          console.log(error)
        })
      }
      return await new Promise(function (resolve) { resolve(false) })
    },

    async fileInputChange(event){
      let files = event.target.files
      if (files && files.length > 0) {

        $('.fileFailMsg').empty()
        if (!this.checkFileNum(files.length)) {
          $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.submission.files.number.error'))
          this.fileAttachFailure = true
          return
        }

        for (let i = 0; i < files.length; i++) {
          let fileAttachment = files[i]
          if (fileAttachment) {
            if (fileAttachment.name && fileAttachment.size) {

              let fileTypeCheck = (/\.(pdf)$/i).test(fileAttachment.name)
              let fileSizeCheck = this.checkFileSize(fileAttachment.size)
              if (fileTypeCheck && !fileSizeCheck) {

                let attachment = await this.getFileAttachment(fileAttachment)
                if (attachment) {
                  this.attachments.push(attachment);
                  let b2mb = Math.round(((fileAttachment.size / this.MB) + 0.00001) * 100) / 100;
                  let fileIndex = this.attachments.length - 1;
                  let svg = '<svg class="svg_close invoiceContactRemoveBtn" data-index="' + fileIndex + '"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#nav-close"></use></svg>'
                  let fileNameDiv = "<div><em>" + fileAttachment.name + "&nbsp " + b2mb + " MB</em>" + "&nbsp&nbsp" + svg + "<br/></div>"

                  $('.fileName').append(fileNameDiv);
                  this.fileAttachFailure = false;
                }

              } else if (!fileTypeCheck) {
                $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.submission.file.extension.error'));
                this.fileAttachFailure = true;
                return;
              } else if (fileSizeCheck) {
                $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.submission.file.size.error'));
                this.fileAttachFailure = true;
                return;
              } else {
                $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.document.error'));
                this.fileAttachFailure = true;
                return;
              }
            } else {
              $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.document.error'));
              this.fileAttachFailure = true;
              return;
            }
          } else {
            $('.fileFailMsg').html(window.Granite.I18n.get('supplier.invoice.document.error'));
            this.fileAttachFailure = true;
            return;
          }
        };
      }
    },

    fileRemove(event) {
      event.preventDefault();

      var index = $(event.target).data('index');
      $(event.target).parent('div').remove();
      this.attachments.splice(index, 1);

      $('.fileName svg').each(function (i) {
        $(event.target).data('index', i);
      });

    },

    async invoiceContactSubmit(event){

      $('#invoiceContactBtn').prop('disabled', true)
      $("#supplier-invoice-loading").show();
      event.preventDefault();
      var captcha = await grecaptcha.execute(captchaV3SiteKey, {action: 'SupplierInvoiceContact'});
      var fname = $("#fname").val();
      var lname = $("#lname").val();
      var email = $("#email").val();
      var company = $("#company").val();
      var phone = $("#phone").val();
      var country = $("#country").val();
      var poNum = $("#poNum").val();
      var invNum = $("#invNum").val();
      var reqType = $("input[name='reqType']:checked").val();
      var addInfo = $("#addInfo").val();
      var emailValid = emailRule($("#email").val());
      var phoneNumberValidateRegex = /(^[(][0-9]+[)][0-9 -]+$)|(^[0-9 -]+$)|(^[+][0-9 -]+$)|(^[(][+][0-9]+[)][0-9 -]+$)/s;
      var phoneNumberValid = phoneNumberValidateRegex.test(phone) && phone.replace(/ |\(|\)|\+|-/g, '').length > 6 && phone.replace(/ |\(|\)|\+|-/g, '').length < 16;
      var invoiceUrl = '.dow.supplier.cmd.json?cmd=invoiceContact&recaptchaResponse=' + captcha;

      var addInfoUrlIndex = addInfo.search(/(?:https?|ftp):\/\/[\n\S]+/g);
      var formValid = true;
      if (fname == "" || lname == "" || email == "" || company == "" || phone == "" || !phoneNumberValid || country == "" || reqType == "" || addInfo == "" || reqType == undefined || !emailValid || addInfoUrlIndex > -1) {
        formValid = false;
      }

      if (this.fileAttachFailure) {
        formValid = false;
      }
      if (fname == "") {
        $("#fnameErrorDivId").show();
      } else {
        $("#fnameErrorDivId").hide();
      }
      if (lname == "") {
        $("#lnameErrorDivId").show();
      } else {
        $("#lnameErrorDivId").hide();
      }
      if (!emailValid) {
        $("#emailErrorDivId").show();
      } else {
        $("#emailErrorDivId").hide();
      }
      if (company == "") {
        $("#companyErrorDivId").show();
      } else {
        $("#companyErrorDivId").hide();
      }
      if (phone == "") {
        $("#phoneErrorDivId").show();
      } else {
        $("#phoneErrorDivId").hide();
      }
      if (phoneNumberValid) {
        $("#phoneNumberInvalid").hide();
      } else {
        $("#phoneNumberInvalid").show();
      }
      if (country == "") {
        $("#CountryErrorDivId").show();
      } else {
        $("#CountryErrorDivId").hide();
      }
      if (addInfo == "") {
        $("#addInfoErrorDivId").show();
        $("#addInfoErrorDivId1").hide();
      } else if (addInfoUrlIndex > -1) {
        $("#addInfoErrorDivId1").show();
        $("#addInfoErrorDivId").hide();
      } else {
        $("#addInfoErrorDivId").hide();
        $("#addInfoErrorDivId1").hide();
      }
      if (reqType == "" || reqType == undefined) {
        $("#reqTypeErrorDivId").show();
      } else {
        $("#reqTypeErrorDivId").hide();
      }

      if (captcha == "") {
        formValid = false;
        this.$store.dispatch('form/trackError', { form: this.cmpId, error: 'Recaptcha validation error' })
        $("#recaptcha-required-error").show();
      } else { $("#recaptcha-required-error").hide(); }

      if (formValid) {
        var formData = {
          firstName: fname,
          lastName: lname,
          email: email,
          companyName: company,
          phone: phone,
          country: country,
          poNumber: poNum,
          invoiceNumber: invNum,
          requestType: reqType,
          comments: addInfo,
          requesttenant: window.tenant || null,
          attachments: "[]"
        };
        if (reqType == "3") {
          formData.attachments = JSON.stringify(this.attachments);
        }
        $.ajax({
          url: invoiceUrl,
          type: "POST",
          data: JSON.stringify(formData),
          dataType: 'json',
          async: false,
          contentType: 'application/json; charset=utf-8',
          success: function (data) {
            $("#supplier-invoice-loading").hide()
            console.log("Success:" + data);
            $("#supplierLandingDiv").hide();
            $("#supplierThxDiv").show();
            this.$store.dispatch('form/trackComplete', this.cmpId)
            $('#invoiceContactBtn').prop('disabled', false)
          }.bind(this),
          error: function (data) {
            $("#supplier-invoice-loading").hide()
            console.log("Form Submit Error:" + data)
            this.$store.dispatch('form/trackError', { form: this.cmpId, error: 'Server error' })
            $("#supplierErrorDiv").show()
            $('#invoiceContactBtn').prop('disabled', false)
          }.bind(this)
        });
      }//form valid end
      else{
        $("#supplier-invoice-loading").hide()
        $('#invoiceContactBtn').prop('disabled', false)
        this.$store.dispatch('form/trackError', { form: this.cmpId, error: 'Input validation error' })
      }
    }

  }

}
</script>

<style lang="scss">
@import './supplier-invoice-contact.scss';
</style>
