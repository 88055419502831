<script>
import PlatformTitle from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/title/title.vue'

export default {
    extends: PlatformTitle
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/title/title.scss';
@import './title.scss';
</style>
