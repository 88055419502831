<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,
  methods: {
    configureColumns: (childs) => {
      childs.forEach((child, i) => {
        if (i < 2) {
          if (child.hasChildNodes) {
            const parent = child.parentElement

            parent.classList.add("dual")
            parent.parentElement.querySelector('.footerTop-category_child_link')?.classList?.add("medium")
          }
        }
      })
    }
  },
  mounted() {
    const childs = this.cmpEl.querySelectorAll('.footerTop-subcategory_child')
    const noChildsArray = []

    this.configureColumns(childs)

    const legal = this.cmpEl.querySelector('#legal')
    const categoryContainer = this.cmpEl.querySelector('.footerTop-category')
    const category = this.cmpEl.querySelectorAll('.footerTop-category_child')
    const catContainer = document.createElement('div')
    const smallContainer = document.createElement('div')

    category.forEach((column) => {
      const subcategory = column.querySelectorAll('.footerTop-subcategory_child')
      if (column.hasChildNodes) {
        subcategory.forEach((subCat) => {
          if (subCat.hasChildNodes) {
            categoryContainer.appendChild(column)
            const childList = subCat.querySelector('ul')

            if (childList !== null) {
              const childrens = childList.querySelectorAll('li')
              if (childrens.length <= 2) {
                subCat.classList.add('small')
                column.append(subCat)
              }
            }
          }
        })
      }

      const ul = column.querySelector('ul')
      if (!ul) {
        column.querySelectorAll("a").forEach((x) => x.classList.add('column-small'))
        noChildsArray.push(column)
      }

      if (ul) {
        if (ul.children.length <= 2) {
          column.querySelectorAll("a").forEach((x) => x.classList.add('column-small'))
        }
      }

      noChildsArray.forEach((nav) => {
        catContainer.append(nav)
      })
      if (ul !== null) {
        if (ul.querySelectorAll('li').length < 6) {
          smallContainer.append(column)
          smallContainer.classList.add('footerTop_column')
        }
        if (ul.querySelectorAll('li').length < 3) {
          catContainer.append(column)
          catContainer.classList.add('footerTop_column')
        }
      }
    })

    if (categoryContainer) {
      categoryContainer.append(smallContainer)
      categoryContainer.append(catContainer)
    }

    if (legal) {
      const legalContainer = Object.assign(document.createElement('div'), {
        id: 'legal-container',
        classList: 'legal'
      })
      legalContainer.appendChild(legal)
      categoryContainer.parentElement.appendChild(legalContainer)
    }

  }
}
</script>

<style lang="scss">
@import './footer.scss';
</style>
