<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,

  created (){

    var buttons = this.cmpEl.querySelectorAll("a")
    var title = this.cmpEl.querySelector("div.corporate-title")?.innerText

    buttons.forEach(el => {
      el.setAttribute("parentId", this.cmpEl.id)
      if(!el.ariaLabel){
        if(title){
          el.ariaLabel = el.text.trim() + ": " + title
        }
        else{
          el.ariaLabel = el.text.trim()
        }
    
      }
    })

  }
}
</script>

<style lang="scss">
@import './small-image-tile.scss';
</style>
