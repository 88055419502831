<template>
  <Teleport :to="this.cmpEl">

    <div class="cmp-resourceFinder__filtersWrapper">
      <div v-show="this.hideFilters != 'yes'" class="cmp-resourceFinder__filtersWrapper--filter">

        <div class="cmp-resourceFinder__filter">
          <p class="filter-title">{{ this.filterOneTitle }}</p>

          <div aria-label="Open Filter Dropdown" @click="this.toggleTagFilter = !this.toggleTagFilter"
            @keyup.enter="this.toggleTagFilter = !this.toggleTagFilter" tabindex="0"
            class="cmp-resourceFinder__filter--input">
            <span class="cmp-resourceFinder__filter--input-heading">{{ this.tagSelected ? this.tagSelected :
              this.filterInputPlaceholder }}</span>
            <svg class="arrow-icon" :class="{ 'active': this.toggleTagFilter }" xmlns="http://www.w3.org/2000/svg" width="15"
              height="16" viewBox="0 0 15 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.9258L2 4.92578H14L8 11.9258Z" fill="#415364" />
            </svg>
          </div>

          <div v-show="this.toggleTagFilter" class="cmp-resourceFinder__filter-list">
            <button @click="this.resetList" aria-label="Reset Filter" class="cmp-resourceFinder__filter-list-item"
              tabindex="0">
              {{ this.getI18nMessage('reset.filters') }}
            </button>
            <div v-for="filterTag in this.filterTags" :key="filterTag" class="cmp-resourceFinder__filter-list-items"
              id="filter-select-box-1">
              <button @click="this.selectTag(filterTag)" class="cmp-resourceFinder__filter-list-item" tabindex="0">
                {{ filterTag }}
              </button>
            </div>
          </div>
        </div>

        <div class="cmp-resourceFinder__filter">
          <p class="filter-title">{{ this.filterTwoTitle }}</p>
          <div aria-label="Open Filter Dropdown" @click="this.toggleTypeFilter = !this.toggleTypeFilter"
            @keyup.enter="this.toggleTypeFilter = !this.toggleTypeFilter" tabindex="0"
            class="cmp-resourceFinder__filter--input">
            <span class="cmp-resourceFinder__filter--input-heading">{{ this.typeSelected ? this.typeSelected :
              this.filterInputPlaceholder }}</span>
            <svg class="arrow-icon" :class="{ 'active': this.toggleTypeFilter }" xmlns="http://www.w3.org/2000/svg" width="15"
              height="16" viewBox="0 0 15 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.9258L2 4.92578H14L8 11.9258Z" fill="#415364" />
            </svg>
          </div>

          <div v-show="this.toggleTypeFilter" class="cmp-resourceFinder__filter-list">
            <button @click="this.resetList" aria-label="Reset Filter" class="cmp-resourceFinder__filter-list-item"
              tabindex="0">
              {{ this.getI18nMessage('reset.filters') }}
            </button>
            <div v-for="filterType in this.filterTypes" :key="filterType" class="cmp-resourceFinder__filter-list-items"
              id="filter-select-box-2">
              <button @click="this.selectType(filterType)" class="cmp-resourceFinder__filter-list-item" tabindex="0">
                {{ filterType }}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="cmp-resourceFinder__content">
      <ul class="cmp-resourceFinder__content--list">
        <a v-for="document in this.paginatedDocuments" :key="document" target="_blank" type="application/pdf"
          :href="document.assetPath" class="cmp-resourceFinder__content--list-item">
          <li>
            <p>
              {{ document.title }}
            </p>
            <span class="cmp-resourceFinder__content--list-item-sub">
              {{ document.subTitle != null ? '(' +
                document.subTitle + ')' : '' }}</span>
            <svg class="download-icon" data-sly-test="${icon == 'downloadIcon'}" xmlns="http://www.w3.org/2000/svg"
              width="18" height="17" viewBox="0 0 18 17" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.49816 9.97616L9.49816 1.14453H8.49816L8.49816 9.97553L5.16948 6.6461L4.81597 6.2925L4.10878 6.99953L4.4623 7.35312L8.64488 11.5367L8.99848 11.8903L9.35207 11.5367L13.5347 7.35313L13.8882 6.99953L13.181 6.29251L12.8275 6.6461L9.49816 9.97616ZM1.96875 10.6841V11.1841V14.5309C1.96875 14.8421 2.09238 15.1406 2.31243 15.3606C2.53249 15.5807 2.83095 15.7043 3.14216 15.7043H14.8542C15.1654 15.7043 15.4638 15.5807 15.6839 15.3606C15.9039 15.1406 16.0276 14.8421 16.0276 14.5309V11.1841V10.6841H17.0276V11.1841V14.5309C17.0276 15.1073 16.7986 15.6601 16.391 16.0677C15.9834 16.4753 15.4306 16.7043 14.8542 16.7043H3.14216C2.56574 16.7043 2.01292 16.4753 1.60533 16.0677C1.19773 15.6601 0.96875 15.1073 0.96875 14.5309V11.1841V10.6841H1.96875Z"
                fill="#E80033" />
            </svg>
          </li>
        </a>
      </ul>
    </div>

    <nav v-if="this.itemsPerPage < this.totalDocuments">
      <ul class="cmp-resourceFinder__pagination-pagebtns">
        <li class="cmp-resourceFinder__page-item cmp-resourceFinder__button-arrow-left"
          :class="{ disabled: currentPage === 1 }">
          <a class="cmp-resourceFinder__page-link" href="#" @click.prevent="changePage(currentPage - 1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33"
              fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.8477 11.4258L20.0137 15.4257H9V17.4252H20.0137L15.8477 21.4258H18.793L24 16.4258L18.793 11.4258H15.8477Z" />
            </svg>
          </a>
        </li>
        <li v-for="page in totalPages" :key="page" class="cmp-resourceFinder__button-page page-item" :class="{ 'active': currentPage === page}"
          @click="gotoPage(page)">
          <a class="cmp-resourceFinder__page-link" href="#" @click.prevent style="display: block; height: 100%;" @keypress.enter="gotoPage(page)">
            {{ page }}
          </a>
        </li>

        <li class="cmp-resourceFinder__page-item cmp-resourceFinder__button-arrow-right"
          :class="{ disabled: currentPage === totalPages }">
          <a class="cmp-resourceFinder__page-link" href="#" @click.prevent="changePage(currentPage + 1)">
            <svg class="pagination-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33"
              fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.8477 11.4258L20.0137 15.4257H9V17.4252H20.0137L15.8477 21.4258H18.793L24 16.4258L18.793 11.4258H15.8477Z" />
            </svg>
          </a>
        </li>
      </ul>
    </nav>

  </Teleport>
</template>

<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
  extends: CoreComponent,
  data() {
    return {
      fileList: [],
      totalDocuments: null,
      filterInputPlaceholder: null,
      filterOneTitle: null,
      filterTwoTitle: null,
      filterTags: [],
      filterTypes: [],
      hideFilters: false,
      toggleTagFilter: false,
      toggleTypeFilter: false,
      tagSelected: false,
      typeSelected: false,
      authoredFilterTags: null,
      authoredFilterTypes: null,
      currentPage: 1,
      itemsPerPage: 100
    };
  },
  mounted() {
    let data = JSON.parse(this.cmpDataLayer)
    this.fileList = data.embeddableProperties.FileList
    this.filterInputPlaceholder = data.embeddableProperties.FilterByLabel
    this.filterOneTitle = data.embeddableProperties.FilterOneHeading
    this.filterTwoTitle = data.embeddableProperties.FilterTwoHeading
    this.itemsPerPage = Number(data.embeddableProperties.NumberOfItems)
    this.hideFilters = data.embeddableProperties.HideFilters
    this.authoredFilterTags = data.embeddableProperties.FilterTag
    this.authoredFilterTypes = data.embeddableProperties.FilterType

    this.initializeFilterLists(this.fileList)
    this.totalDocuments = this.fileList.length
  },
  methods: {
    initializeFilterLists(fileList) {
      this.authoredFilterTags.forEach(tag => {
        this.filterTags.push(tag)
      });
      this.authoredFilterTypes.forEach(type => {
        this.filterTypes.push(type)
      });
    },
    resetList() {
      this.closeFilters()
      this.gotoPage(1)
      this.tagSelected = null
      this.typeSelected = null
    },
    selectTag(tag) {
      this.closeFilters()
      this.tagSelected = tag
    },
    selectType(type) {
      this.closeFilters()
      this.typeSelected = type
    },
    closeFilters() {
      this.toggleTagFilter = false
      this.toggleTypeFilter = false
    },
    getI18nMessage(key) {
      return window.Granite.I18n.get(key);
    },
    gotoPage(page) {
      this.currentPage = page;
    },
    changePage(page) {
      this.currentPage = page;
      if (page < 1) {
        this.currentPage = 1;
      } else if (page > this.totalPages) {
        this.currentPage = this.totalPages;
      } else {
        this.currentPage = page;
      }
    }
  },

  computed: {
    filteredDocuments() {
      return this.fileList.filter(doc => {
        this.gotoPage(1)
        let tagMatch = this.tagSelected ? doc.filterTag.includes(this.tagSelected) : true;
        let typeMatch = this.typeSelected ? doc.filterType.includes(this.typeSelected) : true;
        return tagMatch && typeMatch;
      });
    },
    paginatedDocuments() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      return this.filteredDocuments.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDocuments.length / this.itemsPerPage);
    }

  }
};
</script>

<style lang="scss">
@import "./resourceFinder.scss";
</style>
