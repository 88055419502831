<template>
    <Teleport to=".triverconserv-contactus-form">
        <ContactUsForm
            :elqFormName = "elqFormName"
            :elqSiteId = "elqSiteId"
            :contactUsForm ="contactUsForm"
            :formSize="formSize"
            />
    </Teleport>
</template>

<script lang="ts" setup>

import { ref, reactive, onMounted } from 'vue'
import { type ContactUsRuleForm } from '../forms/FormInterface'
import ContactUsForm from '../forms/ContactUsForm.vue'

const formSize = ref('default')
const elqFormName = ref('201710_DowCorporate_NA_Optin_TRiverContactUs')
const elqSiteId = ref('279295639')

const contactUsForm = ref({
    name: 'triverconservContactUsForm', 
    title: 'ContactUs',
    fields: {} as ContactUsRuleForm
})

contactUsForm.value.fields = reactive<ContactUsRuleForm>({
    firstName: {
        label: 'First Name*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    lastName: {
        label: 'Last Name*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    email: {
        label: 'Email Address*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    address1: {
        label: 'Address 1', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    address2: {
        label: 'Address 2', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    city: {
        label: 'City', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    stateProv: {
        label: 'State or Province', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    zipPostal: {
        label: 'Zip or Postal Code*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    busPhone: {
        label: 'Business Phone', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    country: {
        label: 'Country*', 
        value: '', 
        type: 'select', 
        options: [],
        isShow: ref(true)
    },
    additionalInfo: {
        label: 'Please provide additional information to help us respond to your request.*', 
        value: '', 
        type: 'textarea',
        isError: false, 
        isShow: ref(true)
    },
    inquiryPurpose: {
        label: 'What would you like to know?*', 
        value: '', 
        type: 'select',
        options: [], 
        isShow: ref(false),
        id: 'inquiryPurpose'
    },
    company: {
        label: 'Company*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(false)
    },
    productApplication: {
        label: 'PE Product Application*', 
        value: '', 
        type: 'select', 
        options: [], 
        isShow: ref(false)
    },
})

onMounted(() => {
    initCountryData();
})

function initCountryData(): void {
    window.countries.forEach((item) => {
        contactUsForm.value.fields.country.options?.push({
            value: item.name,
            label: item.name
        })
    })
}

</script>

<style lang="scss">
@import './triverconserv-contact-form.scss';
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/shared/loading-dots/style';
</style>
