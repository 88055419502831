<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,
  data() {
    return {
      selectedTags: {},
      currentPage: 1,
      itemsPerPage: 15,
      totalItems: 150,
      selectedFacetsContainer: null,
      selectedFacetsRow: null,
      lastFetchItemCount: null
    }
  },
  mounted() {
    const anchorTags = this.cmpEl.querySelectorAll('a[href="#"]')
    anchorTags.forEach((anchor) => {
      anchor.addEventListener('click', (event) => {
        event.preventDefault()
      })
    })

    const blockFilterByElements =
      this.cmpEl.querySelectorAll('.block__filter-by')
    blockFilterByElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        element.classList.toggle('active')
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.classList.toggle('active')
        }
      })
    })

    this.selectedFacetsContainer = this.cmpEl.querySelector(
      '.selected-facets-container'
    )
    this.selectedFacetsRow = this.cmpEl.querySelector('.selected-facets-row')
    const clearAllLink = this.cmpEl.querySelector('.clear-all-facets-link')
    const listItems = this.cmpEl.querySelectorAll('.block__filters li a')
    const dropDownCategory = this.cmpEl.querySelectorAll(
      '.dropdown-subcategory li a'
    )
    const newsListItems = this.cmpEl.querySelectorAll('.newstype-list li a')
    const showFiltersButton = this.cmpEl.querySelector(
      '.filters__buttons .link:nth-child(1)'
    )
    const hideFiltersButton = this.cmpEl.querySelector(
      '.filters__buttons .link:nth-child(2)'
    )
    const newsListFilters = this.cmpEl.querySelector(
      '.news-list-filter__filters'
    )
    const loadMoreButton = this.cmpEl.querySelector('.load-more-button')
    // Combine items for event handling
    const combinedItems = [...listItems, ...newsListItems]
    combinedItems.forEach((item) => {
      item.addEventListener('click', this.handleItemClick)
    })

    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', () => {
        this.fetchNewsByTag('', '', '', this.currentPage + 1)
      })
    }
    if (showFiltersButton && hideFiltersButton && newsListFilters) {
      showFiltersButton.addEventListener('click', (event) => {
        event.preventDefault()
        showFiltersButton.style.display = 'none'
        hideFiltersButton.style.display = 'block'
        newsListFilters.classList.add('active')
      })

      hideFiltersButton.addEventListener('click', (event) => {
        event.preventDefault()
        hideFiltersButton.style.display = 'none'
        showFiltersButton.style.display = 'block'
        newsListFilters.classList.remove('active')
      })
    }

    clearAllLink.addEventListener('click', (event) => {
      event.preventDefault()
      while (this.selectedFacetsContainer.firstChild) {
        this.selectedFacetsContainer.removeChild(
          this.selectedFacetsContainer.firstChild
        )
      }
      this.selectedFacetsRow.classList.add('hidden')
      const selectedElements = this.cmpEl.querySelectorAll(
        '.filter-sidebar .selected'
      )
      selectedElements.forEach((element) => {
        element.classList.remove('selected')
      })

      this.selectedTags = {}
      this.fetchNewsByTag()
    })

    this.selectedFacetsContainer.addEventListener('click', (event) => {
      if (
        event.target.classList.contains('selected-facet-link') ||
        event.target.classList.contains('close-icon')
      ) {
        const itemName = event.target
          .closest('.selected-facet')
          .querySelector('.selected-facet-link').textContent
        const selectedFacetElement = event.target.closest('.selected-facet')
        const combinedItems = [...listItems, ...dropDownCategory]
        const originalItem = Array.from(combinedItems).find(
          (item) => item.textContent === itemName
        )
        if (originalItem) {
          originalItem.classList.remove('selected')
        }
        event.target.closest('.selected-facet').remove()
        const tagLink = selectedFacetElement.querySelector(
          '.selected-facet-link'
        )
        const tagId = tagLink.getAttribute('data-tag-id')
        const classList = tagLink.classList
        let tagType
        let tagValue = tagLink.textContent.trim()
        if (classList.contains('market-tags')) {
          tagType = 'Market'
        } else if (classList.contains('product-tags')) {
          tagType = 'Product'
        } else if (classList.contains('corporate-tags')) {
          tagType = 'Corporate'
        } else if (classList.contains('year-filter')) {
          tagType = 'year'
        } else if (classList.contains('month-filter')) {
          tagType = 'month'
        }
        if (tagType) {
          this.removeTag(tagType, tagValue, tagId)
        }

        selectedFacetElement.remove()

        if (this.selectedFacetsContainer.children.length === 0) {
          this.selectedFacetsRow.classList.add('hidden')
        }

        this.fetchNewsByTag('', '', '', 1)
      }
    })
    this.fetchNewsByTag()
  },
  methods: {
    fetchNewsByTag(tagValue, tagType, tagId, page = 1) {
      let newsPathValue
      const newsListComponent = document.getElementById('news-items-container')
      if (newsListComponent) {
        newsPathValue = newsListComponent.getAttribute('data-news-path')
      }
      this.currentPage = page
      let fullURL = window.location.href
      let basePart = fullURL.split('?')[0].split('#')[0]
      basePart = basePart.replace('.html', '')
      let servletURL = basePart + '/.dow.newslist.json'
      var params = {
        newsPath: newsPathValue ? newsPathValue : '',
        page: this.currentPage
      }

      if (tagType === 'year' || tagType === 'month') {
        params[tagType] = tagValue
      }

      Object.assign(params, this.selectedTags)

      var queryString = Object.keys(params)
        .map((key) => {
          let value = params[key]
          if (Array.isArray(value)) {
            value = value.join('|')
          } else {
            value = encodeURIComponent(value)
              .replace(/%2F/g, '/')
              .replace(/%3A/g, ':')
          }
          return `${encodeURIComponent(key)}=${value}`
        })
        .join('&')
      var xhr = new XMLHttpRequest()
      xhr.open('GET', `${servletURL}?${queryString}`, true)
      xhr.onload = () => {
        if (xhr.status === 200) {
          var newsData = JSON.parse(xhr.responseText)
          this.updateNewsList(newsData) 
          var isLastPage =
            newsData.length - this.lastFetchItemCount < this.itemsPerPage
          this.updateLoadMoreButton(newsData.length, !isLastPage)
          this.lastFetchItemCount = newsData.length
        } else {
          console.error('Error fetching data: ', xhr.statusText)
        }
      }
      xhr.send()
    },
    updateLoadMoreButton(itemCount, shouldDisplayButton) {
      const loadMoreButton = document.querySelector('.load-more-button')
      const displayedItems = this.currentPage * this.itemsPerPage

      if (loadMoreButton) {
        if (itemCount >= this.itemsPerPage && shouldDisplayButton) {
          loadMoreButton.style.display = 'flex'
        } else {
          loadMoreButton.style.display = 'none'
        }
      }
    },

    formatDate(dateString) {
      const parts = dateString.split('/')
      const month = parseInt(parts[0], 10) - 1 // Month is 0-indexed
      const day = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)

      const date = new Date(year, month, day)

      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }

      return date.toLocaleDateString('en-US', options)
    },

    addTag(tagType, tagValue, tagId) {
      if (tagType === 'year' || tagType === 'month') {
        this.selectedTags[tagType] = tagValue
      } else {
        if (!this.selectedTags[tagType]) {
          this.selectedTags[tagType] = []
        } else if (!Array.isArray(this.selectedTags[tagType])) {
          this.selectedTags[tagType] = [this.selectedTags[tagType]]
        }
        if (!this.selectedTags[tagType].includes(tagId)) {
          this.selectedTags[tagType].push(tagId)
        }
      }
    },

    removeTag(tagType, tagValue, tagId) {
      if (tagType === 'year' || tagType === 'month') {
        delete this.selectedTags[tagType]
      } else {
        if (this.selectedTags[tagType]) {
          if (!Array.isArray(this.selectedTags[tagType])) {
            this.selectedTags[tagType] = [this.selectedTags[tagType]]
          }

          this.selectedTags[tagType] = this.selectedTags[tagType].filter(
            (t) => t !== tagId
          )

          if (this.selectedTags[tagType].length === 0) {
            delete this.selectedTags[tagType]
          }
        }
      }
    },
    updateNewsList(newsData) {
      const container = document.getElementById('news-items-container')
      let htmlContent = ''

      newsData.forEach((item) => {
        const formattedDate = this.formatDate(item.releaseDate)
        htmlContent += `
      <li>
        <a href="${item.path}">
          <p class="list__date">${formattedDate}</p>
          <div>
            <h3 class="headings__color--blue typography--arial headings__alignment--left">
              ${item.title}<i class="list__arrow"></i>
            </h3>
          </div>
          <p class="list__info">${item.description || ''}</p>
        </a>
      </li>
    `
      })
      if (container) {
        container.innerHTML = htmlContent
      }
      if (newsData.length < this.totalItems) {
        this.createLoadMoreButton(container)
      }
    },
    createLoadMoreButton(container) {
      const existingButton = document.querySelector('.load-more-button')
      if (existingButton) {
        existingButton.remove()
      }

      const loadMoreButton = document.createElement('div')
      loadMoreButton.className = 'filters__buttons load-more-button'
      loadMoreButton.innerHTML = `<a aria-label="button" href="#" class="cta cta--secondary">Load more</a>`

      loadMoreButton.querySelector('a').addEventListener('click', (e) => {
        e.preventDefault()
        this.fetchNewsByTag('', '', '', this.currentPage + 1)
      })

      const newsContainer = container.closest('.news-container')
      if (newsContainer) {
        newsContainer.appendChild(loadMoreButton)
      }
    },
    handleItemClick(event) {
      event.preventDefault()
      const target = event.target
      const tagValue = target.textContent.trim()
      const tagId = target.getAttribute('data-tag-id')
      let tagType

      if (target.classList.contains('market-tags')) {
        tagType = 'Market'
      } else if (target.classList.contains('product-tags')) {
        tagType = 'Product'
      } else if (target.classList.contains('corporate-tags')) {
        tagType = 'Corporate'
      } else if (target.classList.contains('year-filter')) {
        tagType = 'year'
      } else if (target.classList.contains('month-filter')) {
        tagType = 'month'
      }

      if (tagType) {
        // For year and month, pass tagValue; for others, pass tagId
        if (tagType === 'year' || tagType === 'month') {
          this.addTag(tagType, tagValue)
          this.fetchNewsByTag(tagValue, tagType, tagValue)
        } else {
          this.addTag(tagType, null, tagId)
          this.fetchNewsByTag(tagValue, tagType, tagId)
        }
      }
      if (!event.target.classList.contains('selected')) {
        this.selectedFacetsRow.classList.remove('hidden')

        const clonedItem = event.target.cloneNode(true)
        clonedItem.classList.add('selected-facet-link')

        const facetBox = document.createElement('div')
        facetBox.classList.add('facet-box')

        const selectedFacet = document.createElement('div')
        selectedFacet.classList.add('selected-facet')

        const closeIcon = document.createElement('div')
        closeIcon.classList.add('close-icon')

        facetBox.appendChild(clonedItem)
        facetBox.appendChild(closeIcon)
        selectedFacet.appendChild(facetBox)

        event.target.classList.add('selected')
        this.selectedFacetsContainer.appendChild(selectedFacet)
      }
    }
  }
}
</script>

<style lang="scss">
@import './corporate-news-list.scss';
</style>
