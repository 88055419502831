<script>
import CoreComponent from '../CoreComponent.vue'
import { detectFileDownloadLink } from '../../core/ACDLHandler'

export default {
    extends: CoreComponent,
    mounted() {
        //File Download Tracking
        detectFileDownloadLink(this.cmpEl)
    }
}
</script>

<style lang="scss">
@import './button.scss';
</style>
