<script>
  import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
  export default {
    extends: CoreComponent
  }
</script>

<style lang="scss">
  @import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/inPageGlobalSearch/css/inPageGlobalSearch.scss';
  @import '../GlobalHeaderSearchBar/GlobalHeaderSearchBar.scss';
  @import './InPageSearchBar.scss';
</style>
