import {
  STATUS_FINISHED,
  STATUS_PAUSED,
  STATUS_RESUMED,
  STATUS_STARTED,
  STATUS_UNSTARTED,
  MILESTONE_INTERVAL
} from '@platform/dow-platform/components/core/embed/embeddable/common'
import { updateComponentDataLayer } from '@platform/dow-platform/components/core/ACDLHandler'

export class Analytics {
  constructor(store, componentId) {
    this.started = false
    this.ended = false
    this.status = STATUS_UNSTARTED
    this.milestone = MILESTONE_INTERVAL
    this.currentTime = 0
    this.duration = undefined

    this.store = store
    this.componentId = componentId
  }

  trackVideoTime(currentTime) {
    if (currentTime && !this.started) this.trackVideoStatus(STATUS_STARTED)
    const milestone = this.duration * (this.milestone / 100)
    if (currentTime > milestone) {
      this.store.dispatch('assets/videoMilestone', {
        cmpId: this.componentId,
        milestone: this.milestone
      })

      this.milestone = this.milestone + MILESTONE_INTERVAL
    }
  }

  trackVideoStatus(status) {
    if (status === STATUS_STARTED) this.started = true
    if (status === STATUS_FINISHED) this.ended = true

    this.store.dispatch('assets/videoInteraction', {
      cmpId: this.componentId,
      status
    })
  }
}

export class DiamondTVAnalytics extends Analytics {
  constructor(iframe, store, componentId, title) {
      super(store, componentId)

      import(
          /* webpackChunkName: 'qumu-player-sdk' */ '@enghouse-qumu/player-sdk'
      )
          .then(({ PlayerSdk }) => {
              const player = new PlayerSdk(iframe)
              player.readyPromise.then(() => {
                  player.getPresentation().then(({ title }) =>
                    updateComponentDataLayer(componentId, {
                        embeddableProperties: {
                            videoTitle: title,
                            videoURL: iframe.src
                        }
                    })
                  )
                  player
                      .getDuration()
                      .then((duration) => (this.duration = duration))
                  player.addEventListener('play', () => {
                      this.trackVideoStatus(
                          this.started ? STATUS_RESUMED : STATUS_STARTED
                      )
                  })
                  player.addEventListener('pause', () =>
                      this.trackVideoStatus(STATUS_PAUSED)
                  )
                  player.addEventListener('ended', () =>
                      this.trackVideoStatus(STATUS_FINISHED)
                  )
                  player.addEventListener('timeupdate', (currentTime) =>
                      this.trackVideoTime(currentTime)
                  )
              })
          })
          .catch((e) => console.log(e))
  }
}

export class YouTubeAnalytics extends Analytics {
  constructor(store, componentId, title) {
      super(store, componentId)

      this.componentId = componentId
      this.title = title
      if (!window.YT) {
          store
              .dispatch(
                  'browser/script',
                  'https://www.youtube.com/iframe_api'
              )
              .catch(window.onYouTubeIframeAPIFailure)
          new Promise((resolve, reject) => {
              window.onYouTubeIframeAPIReady = function () {
                  resolve(window.YT)
              }
              window.onYouTubeIframeAPIFailure = function () {
                  reject('YouTube API Failed to Load')
              }
          }).then((YT) => this.initPlayer(YT))
      } else {
          this.initPlayer(window.YT)
      }
  }

  initPlayer(YT) {
      const player = new YT.Player(this.componentId)

      player.addEventListener('onReady', () => {
          updateComponentDataLayer(this.componentId, {
              embeddableProperties: {
                  videoTitle: player.getVideoData().title,
                  videoURL: player.getVideoUrl()
              }
          })
      })

      player.addEventListener('onStateChange', ({ data: status }) => {
          status =
              (this.started &&
                  status === YT.PlayerState.PLAYING &&
                  STATUS_RESUMED) ||
              (status === YT.PlayerState.PLAYING && STATUS_STARTED) ||
              (status === YT.PlayerState.ENDED && STATUS_FINISHED) ||
              (status === YT.PlayerState.PAUSED && STATUS_PAUSED)

          if (status) this.trackVideoStatus(status)
      })

      window.addEventListener('message', (event) => {
          if (event.source === player.getIframe().contentWindow) {
              const data = JSON.parse(event.data)
              if (data.event === 'infoDelivery' && data.info?.currentTime) {
                  this.trackVideoTime(data.info.currentTime)
                  this.duration = this.duration || data.info.duration
              }
          }
      })
  }
}

export class InlineVideoTracking extends Analytics {
  constructor(video, store, componentId) {
    super(store, componentId)

    this.video = video
    this.duration = video.duration

    let name = video.src.substr(video.src.lastIndexOf('/') + 1)
    name = name.substr(0, name.lastIndexOf('.'))

    updateComponentDataLayer(componentId, {
      embeddableProperties: {
        videoURL: video.src,
        videoTitle: name
      }
    })
    this.initEvents()
  }

  initEvents() {
    this.video.addEventListener('play', () => {
      this.trackVideoStatus(this.started ? STATUS_RESUMED : STATUS_STARTED)
    })
    this.video.addEventListener('pause', () => {
      if (
        this.video.currentTime > 0 &&
        this.video.currentTime < this.duration
      ) {
        this.trackVideoStatus(STATUS_PAUSED)
      }
    })
    this.video.addEventListener('ended', () => {
      this.trackVideoStatus(STATUS_FINISHED)
    })
    this.video.addEventListener('timeupdate', () => {
      this.trackVideoTime(this.video.currentTime)
    })
  }
}
