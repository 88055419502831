<template>
  <Teleport :to="cmpEl">
    <div class="cmp-jobs-feed__list">
      <template v-if="hasResults">
        <div
          class="cmp-jobs-feed__item"
          v-for="result in results"
          :key="result.uniqueId"
        >
          <h6 class="cmp-jobs-feed__item-heading">
            <span class="cmp-jobs-feed__item-badge">
              <span class="cmp-jobs-feed__item-badge-icon"></span>
              {{ result.raw.commoncontenttype[0] }}
            </span>
            <a
              :href="jobUrl(result.raw.dow_jobreqid, result.raw.sysfiletype)"
              :title="result.title"
              @click="handleClick(result)"
            >
              {{ result.title }}
            </a>
          </h6>
          <div
            class="cmp-jobs-feed__item-excerpt"
            v-html="result.excerpt"
          ></div>
          <div class="cmp-jobs-feed__item-footer">
            <span>{{ result.raw.dow_jobprimarylocationclean }}</span>
            <span>{{ formatDate(result.raw.date) }}</span>
            <span>{{ result.raw.dow_jobreqtype }}</span>
          </div>
        </div>
      </template>
      <div class="cmp-jobs-feed__list-loading" v-if="isLoading">
        <LoadingDots />
      </div>
      <div
        class="cmp-jobs-feed__list-no-results"
        v-if="!hasResults && !isLoading"
      >
        {{ getLabel('corporate.no.jobs.found') }}
      </div>
    </div>
  </Teleport>
</template>
<script>
import CoveoComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/ui/coveoComponent/CoveoComponent.vue'
import LoadingDots from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/blocks/svg-elements/loading-dots/loading-dots.vue'
import i18nHelper from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/myAccount/helper/i18n-helper.js'
import {
  buildResultList,
  buildResultsPerPage,
  loadAdvancedSearchQueryActions,
  loadSearchAnalyticsActions,
  loadClickAnalyticsActions
} from '@coveo/headless'

export default {
  extends: CoveoComponent,
  components: { LoadingDots },
  data: function () {
    return {
      analytics: null,
      controller: null,
      count: 0,
      type: 'input',
      mySelector: 'CorporateDowComJobFinder'
    }
  },
  computed: {
    results() {
      return this.state?.results || null
    },
    noOfItems() {
      return parseInt(this.cmpEl.getAttribute('data-no-of-items'))
    },
    state() {
      this.count
      return this.controller?.state
    },
    hasResults() {
      return this.state?.hasResults
    },
    isLoading() {
      return !this.state || this.state.isLoading
    },
    searchEngine() {
      return this.$store.state.search.engines[this.mySelector]?.engine
    }
  },
  methods: {
    getLabel: function (key, value) {
      return !!i18nHelper.getI18nMessage(key, value)
        ? i18nHelper.getI18nMessage(key, value)
        : key
    },
    getLocale() {
      return this.cmpEl.getAttribute('data-locale') || 'en-us'
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      const currentYear = new Date().getFullYear()
      const yearOfTimestamp = date.getFullYear()
      if (yearOfTimestamp === currentYear) {
        const options = { month: 'long', day: 'numeric' }
        return date.toLocaleDateString(this.getLocale(), options)
      } else {
        return `${date.getMonth() + 1}/${date.getDate()}/${yearOfTimestamp}`
      }
    },
    jobUrl(jobId, extension) {
      const locale = this.getLocale()
      return `/${locale}/careers/jobs/position.${jobId}.${extension}`
    },
    pad0(value) {
      return String(value).padStart(2, '0')
    },
    handleClick(result) {
      if (!this.searchEngine || !result) {
        console.error('search engine or result is not defined')
        return
      }

      const analytics = loadClickAnalyticsActions(this.searchEngine)
      this.searchEngine.dispatch(analytics.logDocumentOpen(result))
    }
  },
  watch: {
    searchEngine(newVal, oldVal) {
      if (newVal) {
        this.analytics = loadSearchAnalyticsActions(this.searchEngine)
        this.controller = buildResultList(this.searchEngine, {
          options: {
            fieldsToInclude: [
              'date',
              'sysfiletype',
              'commoncontenttype',
              'dow_jobreqtype',
              'dow_jobreqid',
              'dow_jobprimarylocationclean'
            ]
          }
        })
        this.controller.subscribe(() => this.count++)

        buildResultsPerPage(this.searchEngine, {
          initialState: {
            numberOfResults: this.noOfItems
          }
        })

        const today = new Date()
        const dateStr = `${today.getFullYear()}/${this.pad0(
          today.getMonth() + 1
        )}/${this.pad0(today.getDate())}`
        const timeStr = `${this.pad0(today.getHours())}:${this.pad0(
          today.getMinutes()
        )}:${this.pad0(today.getSeconds())}`

        const advQuery = loadAdvancedSearchQueryActions(this.searchEngine)
        const advAction = advQuery.registerAdvancedSearchQueries({
          aq: `((@commoncontenttype==Job @dow_jobenddate>${dateStr}@${timeStr}) OR NOT @commoncontenttype==Job) ${this.cmpDescription}`
        })
        this.searchEngine.dispatch(advAction)
        this.searchEngine.executeFirstSearch()
      }
    }
  }
}
</script>

<style lang="scss">
@import './jobsFeed.scss';
</style>
