<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";
var markers = new Array();
export default {
    extends: CoreComponent,
    mounted: async function() {
        console.log("location finder created")
        await fetch(`https://maps.googleapis.com/maps/api/js?key=<API_KEY>&callback=initMap`, { mode: 'no-cors'});
            var centerLatitude = parseFloat($('#lat').text());
            var centerLongitude = parseFloat($("#long").text());
            var zoom = parseInt($("#zoom").text());

var centerCoords = {
lat : centerLatitude,
lng : centerLongitude
};

const map = new google.maps.Map(document.getElementById('map'), {
zoom : zoom,
center : centerCoords
});

this.drawLocations(map);
   
    },
    methods: {  
      drawLocations     
    }
    
}



function drawLocations(map){

var openWindow = false;
var result = {};
$.ajax({
url: "/.dow.corplocations.json",
    async: false,
success: function(result){
  
        $.each(result, function(idx, loc){
          var latitude = parseFloat(loc.latitude);
        var longitude = parseFloat(loc.longitude);
        
          if ($.isNumeric(latitude) && $.isNumeric(longitude)){
                var coordinates = {
                    lat : latitude,
                    lng : longitude
                };
                
                var directionsLink = "https://www.google.com/maps/dir/?api=1&destination=" + latitude + "," + longitude;

                var locationInfoBox = '<div class="location-finder__tooltip location-finder__tooltip--active"><div class="tooltip__wrapper"><p class="tooltip__title">' + loc.locationName + '</p><div class="tooltip__address">' + loc.address + '</div>';
                
                if (loc.telephone) {
                  locationInfoBox += '<p class="tooltip__number">Tel: <a href="tel:' + loc.telephone + '">' + loc.telephone + '</a></p>';
                }
                
                if (loc.fax) {
                  locationInfoBox += '<p class="tooltip__number">Fax: <a href="tel:' + loc.fax + '">' + loc.fax + '</a></p>';
                }
                
                var directionsLabel = $("#directions").text();
                
                locationInfoBox += '<a class="tooltip__link" target="_blank" href="' + directionsLink + '">' + directionsLabel + '</a></div></div>';

                var icon1 = {
                    path: 'M7.5,11.8171429 C5.20661157,11.8171429 3.34710744,9.93142857 3.34710744,7.60571429 C3.34710744,5.28 5.20661157,3.39428571 7.5,3.39428571 C9.79338843,3.39428571 11.6528926,5.28 11.6528926,7.60571429 C11.6528926,9.93142857 9.79338843,11.8171429 7.5,11.8171429 M7.5,0 C3.34710744,0 0,3.39428571 0,7.60571429 C0,11.8171429 7.5,22 7.5,22 C7.5,22 15,11.8171429 15,7.60571429 C15,3.39428571 11.6528926,0 7.5,0',
                    fillColor: '#E80033',
        fillOpacity: 1,
                  strokeWeight: 0,
                    scale: 1.5,
                    size: new google.maps.Size(15, 22),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(7.5, 22)
                };

                var icon2 = {
                    path: 'M7.5,11.8171429 C5.20661157,11.8171429 3.34710744,9.93142857 3.34710744,7.60571429 C3.34710744,5.28 5.20661157,3.39428571 7.5,3.39428571 C9.79338843,3.39428571 11.6528926,5.28 11.6528926,7.60571429 C11.6528926,9.93142857 9.79338843,11.8171429 7.5,11.8171429 M7.5,0 C3.34710744,0 0,3.39428571 0,7.60571429 C0,11.8171429 7.5,22 7.5,22 C7.5,22 15,11.8171429 15,7.60571429 C15,3.39428571 11.6528926,0 7.5,0',
                    fillColor: '#193F5E',
        fillOpacity: 1,
                    strokeWeight: 0,
                    scale: 1.5,
                    size: new google.maps.Size(15, 22),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(7.5, 22)
                };

                var marker = new google.maps.Marker({
                    position : coordinates,
                    map: map,
                    icon: icon1,
                    title: loc.locationName
                });
                
                var infowindow = new google.maps.InfoWindow({
                  content: locationInfoBox
                });

                marker.addListener('click', function() {
                  infowindow.open(map, marker);
                });

                google.maps.event.addListener(marker, 'mouseover', function() {
                    marker.setIcon(icon2);
                });

                google.maps.event.addListener(marker, 'mouseout', function() {
                    marker.setIcon(icon1);
                });

                google.maps.event.addListener(marker, 'click', function() {
                    if(openWindow) {
                       openWindow.close();
                    }
            
                    openWindow = infowindow;
                    infowindow.open(map, marker);
                });

                markers.push(marker);
            }
        });
        window.markers = markers;
}
});
}

</script>

<style lang="scss">
@import "./locationFinder.scss";
</style>
