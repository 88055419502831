<template>
  <Teleport :to="downloadButton">
    <svg class="" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 21">
      <g fill="currentColor">
        <path
          d="M17 14a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm-4-5a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm0 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-6-3a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2a1 1 0 0 0 0 2Z" />
        <path fill-rule="evenodd"
          d="M7 1.75a.75.75 0 0 1 .75.75v.763c.662-.013 1.391-.013 2.193-.013h4.113c.803 0 1.532 0 2.194.013V2.5a.75.75 0 0 1 1.5 0v.827c.26.02.506.045.739.076c1.172.158 2.121.49 2.87 1.238c.748.749 1.08 1.698 1.238 2.87c.153 1.14.153 2.595.153 4.433v2.112c0 1.838 0 3.294-.153 4.433c-.158 1.172-.49 2.121-1.238 2.87c-.749.748-1.698 1.08-2.87 1.238c-1.14.153-2.595.153-4.433.153H9.944c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87c-.153-1.14-.153-2.595-.153-4.433v-2.112c0-1.838 0-3.294.153-4.433c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238c.233-.031.48-.056.739-.076V2.5A.75.75 0 0 1 7 1.75ZM5.71 4.89c-1.005.135-1.585.389-2.008.812c-.423.423-.677 1.003-.812 2.009c-.023.17-.042.35-.058.539h18.336c-.016-.19-.035-.369-.058-.54c-.135-1.005-.389-1.585-.812-2.008c-.423-.423-1.003-.677-2.009-.812c-1.027-.138-2.382-.14-4.289-.14h-4c-1.907 0-3.261.002-4.29.14ZM2.75 12c0-.854 0-1.597.013-2.25h18.474c.013.653.013 1.396.013 2.25v2c0 1.907-.002 3.262-.14 4.29c-.135 1.005-.389 1.585-.812 2.008c-.423.423-1.003.677-2.009.812c-1.027.138-2.382.14-4.289.14h-4c-1.907 0-3.261-.002-4.29-.14c-1.005-.135-1.585-.389-2.008-.812c-.423-.423-.677-1.003-.812-2.009c-.138-1.027-.14-2.382-.14-4.289v-2Z"
          clip-rule="evenodd" />
      </g>
    </svg>
  </Teleport>
</template>
<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,
  computed: {
    downloadButton() {
      return this.cmpEl.querySelector('.cmp-eventDetails-dateInfo_addTo')?.querySelector('a');
    }
  },
  methods: {
    getDate: (data) => {
      const dateArray = [];
      data.split(",").forEach((x) => {
        if (/=/.test(x)) {
          x.split("=")
          dateArray.push({
            key: x.split("=")[0],
            value: x.split("=")[1]
          })
        }
      })
      const year = parseInt(dateArray.find(item => item.key === 'YEAR').value);
      const month = parseInt(dateArray.find(item => item.key === 'MONTH').value);
      const day = parseInt(dateArray.find(item => item.key === 'DAY_OF_MONTH').value);
      const hour = parseInt(dateArray.find(item => item.key === 'HOUR').value);
      const minute = parseInt(dateArray.find(item => item.key === 'MINUTE').value);
      const second = parseInt(dateArray.find(item => item.key === 'SECOND').value);

      const date = new Date(Date.UTC(year, month, day, hour, minute, second));
      const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short', year: 'numeric', hour12: false };

      const lastDate = new Intl.DateTimeFormat('en-US', options).format(date);
      const pad = (n) => (n < 10 ? `0${n}` : `${n}`);
      const yearIcs = date.getFullYear();
      const monthIcs = pad(date.getUTCMonth() + 1);
      const dayIcs = pad(date.getUTCDate());
      const hours = pad(date.getUTCHours());
      const minutes = pad(date.getUTCMinutes());
      const seconds = pad(date.getUTCSeconds());

      const icsFDate = `${yearIcs}${monthIcs}${dayIcs}T${hours}${minutes}${seconds}Z`

      return { lastDate, icsFDate }
    }
  },
  mounted() {
    const theDate = this.cmpEl.querySelector('.cmp-eventDetails-dateInfo_container_date_value')
    const date = this.cmpEl.querySelector('.cmp-eventDetails-dateInfo_container_date-both')
    const downloadButton = this.cmpEl.querySelector('.cmp-eventDetails-dateInfo_addTo')?.querySelector('a');
    const viewAllButton = this.cmpEl.querySelector(".cmp-eventDetails-buttons_seeAll")?.querySelector('a')

    viewAllButton.href = `${window.location.origin}/content/dow-com/corporate/us/en/news/events`

    if (downloadButton.querySelector("svg")) {
      downloadButton.querySelector("svg").parentElement.remove()
    }

    const icsTitle = theDate.dataset.icsTitle;
    const icsDescription = theDate.dataset.icsDescription;
    const icsLocation = theDate.dataset.icsLocation;

    const start = theDate.dataset.startDate;
    const end = theDate.dataset.endDate;

    const { lastDate: dateStart, icsFDate: icsStartDate } = this.getDate(start);
    const { lastDate: dateEnd, icsFDate: icsEndDate } = this.getDate(end);

    date.innerHTML = `<strong>Date: </strong>${dateStart} - ${dateEnd}`;

    const icsContent = `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nSUMMARY:${icsTitle}\nDESCRIPTION:${icsDescription}\nLOCATION:${icsLocation}\nDTSTART:${icsStartDate}\nDTEND:${icsEndDate}\nEND:VEVENT\nEND:VCALENDAR`;
    const blob = new Blob([icsContent], { type: "text/calendar;charset=utf-8" });

    if (!downloadButton.hasAttribute("href")) {
      downloadButton.setAttribute("href", "#")
    }

    downloadButton.href = URL.createObjectURL(blob);
    downloadButton.download = icsTitle;
  }
};
</script>

<style lang="scss">
@import "./eventDetails.scss";
</style>
