export default {
    template: ` 
		<div v-if="loading" class="vue-loading-modal-backdrop">
			<div class="component-loader">
				<div class="loader-child loader-bounce1"></div>
				<div class="loader-child loader-bounce2"></div>
				<div class="loader-child loader-bounce3"></div>
			</div>
		</div>`,
    name: 'LoadingDots',
    props: ['loading']
}
