<template>
    <Teleport to=".univation-contactus-form">
        <ContactUsForm
            :elqFormName = "elqFormName"
            :elqSiteId = "elqSiteId"
            :contactUsForm ="contactUsForm"
            :formSize="formSize"
            :subTitle = "subTitle"
            />
    </Teleport>
</template>

<script lang="ts" setup>

import { ref, reactive, onMounted } from 'vue'
import ContactUsForm from '../forms/ContactUsForm.vue'
import { type ContactUsRuleForm } from '../forms/FormInterface'

const formSize = ref('default')
const elqFormName = ref('202003_DowCorporate_NA_Optin_UnivationTechnologies')
const elqSiteId = ref('279295639')
const subTitle = ref('Do you want to know more about UNIPOL™ PE Technology and the PE manufacturing process, products, services and catalysts offered by Univation Technologies ? Simply fill out the form below and a Univation representative will reach out to you.')

const productApplicationSelect = ref([
    "HDPE",
    "Bimodal HDPE",
    "LLDPE Film", 
    "Pipe Conduit",
    "Injection Molding",
    "Metallocene PE",
    "Blow Molding",
    "Rotomolding",
    "Extrusion Coating",
    "Other PE Application"
]);

const inquiryPurposeSelect = ref([
    "I am interested in building a new polyethylene plant",
    "I am interested in learning more about UNIPOL™ PE Resin Products",
    "I am interested in Univation's PREMIER™ Products &amp; Services for my UNIPOL™ PE Process", 
    "I have an inquiry related to equipment for the UNIPOL™ PE Process",
    "I am interested in Univation's polyethylene polymerization catalysts",
    "I am a PE market consultant, research institute, university interested in UNIPOL™ PE Technology",
    "Other (please specify in the box provided below)"
]);

const contactUsForm = ref({
    name: 'univationContactForm', 
    title: 'ContactUs', 
    fields: {} as ContactUsRuleForm
})

function initData(): void {

    //Country Select Data
    window.countries.forEach((item) => {
        contactUsForm.value.fields.country.options?.push({
            value: item.name,
            label: item.name
        })
    })

    // PE Product Application Select Data
    productApplicationSelect.value.forEach((item) =>{
        contactUsForm.value.fields.productApplication.options?.push({
            value: item,
            label: item
        })
    })

    // inquiryPurpose Select Data
    inquiryPurposeSelect.value.forEach((item) =>{
        contactUsForm.value.fields.inquiryPurpose.options?.push({
            value: item,
            label: item
        })
    })
}

contactUsForm.value.fields = reactive<ContactUsRuleForm>({
    inquiryPurpose: {
        label: 'What would you like to know?*', 
        value: '', 
        type: 'select',
        options: [], 
        isShow: ref(true),
        id: 'inquiryPurpose'
    },
    firstName: {
        label: 'First Name*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    lastName: {
        label: 'Last Name*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    email: {
        label: 'Email Address*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    address1: {
        label: 'Address 1', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    address2: {
        label: 'Address 2', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    city: {
        label: 'City', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    }, 
    stateProv: {
        label: 'State or Province', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    zipPostal: {
        label: 'Zip or Postal Code', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    busPhone: {
        label: 'Business Phone', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    company: {
        label: 'Company*', 
        value: '', 
        type: 'input', 
        isError: false, 
        isShow: ref(true)
    },
    country: {
        label: 'Country*', 
        value: '', 
        type: 'select', 
        options: [], 
        isShow: ref(true)
    },
    productApplication: {
        label: 'PE Product Application*', 
        value: '', 
        type: 'select', 
        options: [], 
        isShow: ref(true)
    },
    additionalInfo: {
        label: 'Please provide additional information to help us respond to your request.*', 
        value: '', 
        type: 'textarea',
        isError: false, 
        isShow: ref(true)
    }
})

onMounted(() => {
    initData();
})

</script>

<style lang="scss">
@import './univation-contact-form.scss';
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/shared/loading-dots/style';
</style>
