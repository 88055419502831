<script>
import CoreComponentVue from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponentVue,

  mounted() {
    const lastItem = this.cmpEl.querySelector(
      '.cmp-breadcrumb__list > li:last-child'
    )

    const lastCarrot = this.cmpEl.querySelector(
      '.cmp-breadcrumb__list > li:last-child .cmp-breadcrumb__item-icon'
    )

    if (lastItem) {
      lastItem.style.fontWeight = 'bold'
    }

    if (lastCarrot) {
      lastCarrot.style.display = 'none'
    }
  }
}
</script>

<style lang="scss">
@import './breadcrumb.scss';
</style>
