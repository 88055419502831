<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
    extends: CoreComponent,

  
    methods: {
        closeOtherDropdowns,
        setNewSelectedCountry,
        setNewSelectedRegion

    },
    mounted: function () {
        this.closeOtherDropdowns()
    }
}


    function closeOtherDropdowns (){
      $('.country-finder').find('.filters__dropdown').removeClass('active');
      $('.country-finder').find('.filters__filter-by').removeClass('active');

    }
    
    function setNewSelectedCountry(countryCode, countryTitle){
    	$("#country-dropdown").text(countryTitle);
        $('.corp-country-item').addClass('hidden');
        if (countryCode !== ''){
        	$('.' + countryCode).removeClass('hidden');
        }
      }

    function setNewSelectedRegion (regionCode, regionTitle){
    	setNewSelectedCountry('', 'Country');
      	$("#region-dropdown").text(regionTitle);
      	$('.corp-country-item-dd').addClass('hidden');
  	    $('.' + regionCode).removeClass('hidden');
        }
  

  window.onclick = function(event) {
	  if (!$(event.target).is('.filters__filter-by')){
    	  closeOtherDropdowns();
      }
    }

	$(".country__title").on("click", function(event){
		event.preventDefault();
		$(event.target).closest("a").toggleClass("country__title--active");
	    $(event.target).siblings().toggleClass("country__list--active");
		});	
	$(".filters__filter-by").on("click", function(event){
		event.preventDefault();
		$(event.target).closest("a").toggleClass("active");
	    $(event.target).siblings().toggleClass("active");
		});	
	$(".corp-country-item-dd").on("click", function(event) {
		var countryCode = $(event.target).closest("li").attr("value");
		var countryTitle= $(event.target).closest("li").text();
	    setNewSelectedCountry(countryCode, countryTitle);
	    event.preventDefault();
	    });
    $(".corp-region-item-dd").on("click", function(event) {
    	var regionCode = $(event.target).closest("li").attr("value");
		var regionTitle= $(event.target).closest("li").text();
	    setNewSelectedRegion(regionCode, regionTitle);
	    event.preventDefault();
	    });

</script>

<style lang="scss">
@import "./countryFinder.scss";
</style>
