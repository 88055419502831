<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
  extends: CoreComponent,

  mounted(){
    const graybackground = this.cmpEl.querySelectorAll('.cmp-threeUpImageBand__card--below')
    const cards = this.cmpEl.querySelectorAll('.cmp-threeUpImageBand__card')

    console.log(graybackground)
    console.log(cards)

    cards.forEach((card, index) => {
      card.addEventListener('mouseenter', () => {
        graybackground[index].classList.add('hovered')
      })
      card.addEventListener('mouseleave', () => {
        graybackground[index].classList.remove('hovered')
      })
    })
  }
};
</script>

<style lang="scss">
@import "./threeUpImageBand.scss";
</style>
