<template>
  <div>
    <component
      v-for="(cmp, id) in $store.state.components.dataLayer"
      :key="id"
      :ref="id"
      :is="
        cmp['@type'] in $options.components
          ? cmp['@type']
          : 'PlatformCoreComponent'
      "
    ></component>
  </div>
</template>

<script>
import Title from './corp-platform/components/core/title/title.vue'
import Text from './corp-platform/components/core/text/text.vue'
import Tabs from './corp-platform/components/core/tabs/Tabs.vue'
import Image from './corp-platform/components/core/image/image.vue'
import YouTube from './corp-platform/components/core/embed/embeddable/youtube/youtube.vue'
import Video from './corp-platform/components/core/embed/embeddable/video/video.vue'
import DiamondTv from './corp-platform/components/core/embed/embeddable/diamondtv/diamondtv.vue'
import DiamondTvProcessor from './corp-platform/components/core/embed/processors/diamondtv.vue'
import YouTubeProcessor from './corp-platform/components/core/embed/processors/youtube.vue'
import PlatformCoreComponent from './../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import Button from './corp-platform/components/core/button/button.vue'
import Accordion from './corp-platform/components/core/accordion/accordion.vue'
import Cta from './corp-platform/components/ui/cta/cta.vue'
import ColumnBand from './corp-platform/components/ui/columnBand/columnBand.vue'
import ColumnBandV2 from './corp-platform/components/ui/columnBand-v2/columnBand-v2.vue'
import CorporateTitle from './corp-platform/components/ui/corporate-title/corporate-title.vue'
import CountdownClock from './corp-platform/components/ui/countdown-clock/countdown-clock.vue'
import SmallImageTileBand from './corp-platform/components/ui/small-image-tile-band/small-image-tile-band.vue'
import SmallImageTile from './corp-platform/components/ui/small-image-tile/small-image-tile.vue'
import StockTicker from './corp-platform/components/ui/stock-ticker/stock-ticker.vue'
import ShareOnSocial from './corp-platform/components/ui/shareOnSocial/shareOnSocial.vue'
import Flexband from './corp-platform/components/ui/flexband/flexband.vue'
import DarkModeToggle from './corp-platform/components/ui/dark-mode-toggle/dark-mode-toggle.vue'
import Footer from './corp-platform/components/ui/footer/footer.vue'
import NewFooter from './corp-platform/components/ui/new-footer/new-footer.vue'
import MinimalFooter from './corp-platform/components/ui/minimal-footer/minimal-footer.vue'
import PressRelease from './corp-platform/components/ui/press-release/press-release.vue'
import CorporateNewsList from './corp-platform/components/ui/corporate-news-list/corporate-news-list.vue'
import CorpEvents from './corp-platform/components/commerce/corpEvents/corpEvents.vue'
import EventDetails from './corp-platform/components/ui/eventDetails/eventDetails.vue'
import CorporateBreadcrumb from './corp-platform/components/core/breadcrumb/breadcrumb.vue'
import GlobalHeaderNavigationBar from './corp-platform/components/ui/GlobalHeaderNavigationBar/GlobalHeaderNavigationBar.vue'
import GlobalHeaderSearchBar from './corp-platform/components/ui/GlobalHeaderSearchBar/GlobalHeaderSearchBar.vue'
import InPageSearchBar from './corp-platform/components/ui/InPageSearchBar/InPageSearchBar.vue'
import GlobalHeaderLanguageSwitcher from './corp-platform/components/ui/GlobalHeaderLanguageSwitcher/GlobalHeaderLanguageSwitcher.vue'
import GlobalHeader from './corp-platform/components/ui/GlobalHeader/GlobalHeader.vue'
import SolutionBlock from './corp-platform/components/ui/solutionBlock/solutionBlock.vue'
import PullQuote from './corp-platform/components/ui/pull-quote/pull-quote.vue'
import BenefitsCalculator from './corp-platform/components/ui/benefits-calculator/benefits-calculator.vue'
import LocationFinder from './corp-platform/components/ui/locationFinder/locationFinder.vue'
import CountryFinder from './corp-platform/components/ui/countryFinder/countryFinder.vue'
import VideoModal from './corp-platform/components/ui/videoModal/videoModal.vue'
import TagLinks from './corp-platform/components/ui/tag-links/tag-links.vue'
import SupplierInvoiceContact from './corp-platform/components/commerce/supplier-invoice-contact/SupplierInvoiceContact.vue'
import ImageBand from './corp-platform/components/ui/image-band/image-band.vue'
import VideoBand from './corp-platform/components/ui/videoBand/videoBand.vue'
import IconBand from './corp-platform/components/ui/iconBand/iconBand.vue'
import BlogAuthor from './corp-platform/components/ui/blogAuthor/blogAuthor.vue'
import NewsAndEventsBand from './corp-platform/components/ui/news-and-events-band/news-and-events-band.vue'
import FeatureHighlight from './corp-platform/components/ui/featureHighlight/featureHighlight.vue'
import TriverconservContactForm from './corp-platform/components/commerce/triverconserv-contact-form/TriverconservContactForm.vue'
import UnivationContactForm from './corp-platform/components/commerce/univation-contact-form/UnivationContactForm.vue'
import ContactUsForm from './corp-platform/components/commerce/forms/ContactUsForm.vue'
import MediaTileBand from './corp-platform/components/ui/MediaTileBand/MediaTileBand.vue'
import TileBand from './corp-platform/components/ui/tile-band/tile-band.vue'
import { defineAsyncComponent } from 'vue'
import CorporateCarousel from './corp-platform/components/ui/corporateCarousel/corporateCarousel.vue'
import RelatedArticle from './corp-platform/components/ui/related-article/related-article.vue'
import ThreeUpImageBand from './corp-platform/components/ui/threeUpImageBand/threeUpImageBand.vue'
import ArticleFilter from './corp-platform/components/ui/articleFilter/articleFilter.vue'
import LinkList from './corp-platform/components/ui/link-list/link-list.vue'
import ShortEventList from './corp-platform/components/ui/shortEventList/shortEventList.vue'
import GlobalHeaderNavigationTray from './corp-platform/components/ui/globalHeaderNavigationTray/globalHeaderNavigationTray.vue'
import ResourceFinder from './corp-platform/components/ui/resourceFinder/resourceFinder.vue'
import VerticalSpacer from './corp-platform/components/ui/verticalSpacer/verticalSpacer.vue'
import JobsFeed from './corp-platform/components/ui/jobsFeed/jobsFeed.vue'
const fallbacks = {
  loadingComponent: PlatformCoreComponent,
  errorComponent: PlatformCoreComponent,
};

const TalentCommunityForm = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'talent-community-form' */ "./corp-platform/components/commerce/talent-community-form/TalentCommunityForm.vue"
    ),
});
const StickySubscribe = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'supplier-invoice-contact' */ "./corp-platform/components/commerce/stickySubscribe/stickySubscribe.vue"
    ),
});

const SupplierInvoiceStatus = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'supplier-invoice-status' */ "./corp-platform/components/commerce/supplier-invoice-status/supplier-invoice-status.vue"
    ),
});

const SupplierWorkOrder = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'supplier-invoice-status' */ "./corp-platform/components/commerce/supplier-work-order/supplier-work-order.vue"
    ),
});

const SupplierInvoiceSubmission = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'supplier-invoice-submission' */ "./corp-platform/components/commerce/supplier-invoice-submission/supplier-invoice-submission.vue"
    )
})
const Search = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'search' */ "./corp-platform/components/commerce/search/search.vue"
    ),
});

const CorpSubsiteSearch = defineAsyncComponent({
  ...fallbacks, // Override for custom messaging
  loader: () =>
    import(
      /* webpackChunkName: 'corp-subsite-search' */ "./corp-platform/components/commerce/corp-subsite-search/CorpSubsiteSearch.vue"
    ),
});

const DocCenter = defineAsyncComponent({
...fallbacks, // Override for custom messaging
loader: () =>
    import(
        /* webpackChunkName: 'docCenter' */ './corp-platform/components/commerce/docCenter/docCenter.vue'
    )
});

export default {
  components: {
    PlatformCoreComponent,
    'dow-corp/components/core/title': Title,
    'dow-corp/components/core/text': Text,
    'dow-corp/components/core/tabs': Tabs,
    'dow-corp/components/core/image': Image,
    'dow-corp/components/core/embed/embeddable/youtube': YouTube,
    'dow-corp/components/core/embed/embeddable/video': Video,
    'dow-corp/components/core/embed/embeddable/diamondtv': DiamondTv,
    'dow-corp/components/core/embed/processors/diamondtv': DiamondTvProcessor,
    'dow-corp/components/core/embed/processors/youtube': YouTubeProcessor,
    'dow-corp/components/core/button': Button,
    'dow-corp/components/core/accordion': Accordion,
    'dow-corp/components/other-sites/accordion': Accordion,
    'dow-corp/components/ui/cta': Cta,
    'dow-corp/components/ui/columnBand': ColumnBand,
    'dow-corp/components/ui/columnBand-v2': ColumnBandV2,
    'dow-corp/components/ui/corporate-title': CorporateTitle,
    'dow-corp/components/ui/countdown-clock': CountdownClock,
    'dow-corp/components/ui/small-image-tile-band': SmallImageTileBand,
    'dow-corp/components/ui/small-image-tile': SmallImageTile,
    'dow-corp/components/ui/stock-ticker': StockTicker,
    'dow-corp/components/ui/shareOnSocial': ShareOnSocial,
    'dow-corp/components/ui/pull-quote': PullQuote,
    'dow-corp/components/ui/benefits-calculator': BenefitsCalculator,
    'dow-corp/components/ui/flexband': Flexband,
    'dow-corp/components/ui/dark-mode-toggle': DarkModeToggle,
    'dow-corp/components/ui/footer': Footer,
    'dow-corp/components/ui/new-footer': NewFooter,
    'dow-corp/components/ui/minimal-footer': MinimalFooter,
    'dow-corp/components/ui/press-release': PressRelease,
    'dow-corp/components/ui/corporate-news-list': CorporateNewsList,
    'dow-corp/components/commerce/supplier-invoice-contact':
      SupplierInvoiceContact,
    'dow-corp/components/commerce/supplier-invoice-status':
      SupplierInvoiceStatus,
    'dow-corp/components/commerce/supplier-work-order':
      SupplierWorkOrder,
    'dow-corp/components/commerce/supplier-invoice-submission': SupplierInvoiceSubmission,  
    'dow-corp/components/commerce/stickySubscribe': StickySubscribe,
    'dow-corp/components/commerce/talent-community-form': TalentCommunityForm,
    'dow-corp/components/commerce/corpEvents': CorpEvents,
    'dow-corp/components/commerce/search': Search,
    'dow-corp/components/core/breadcrumb': CorporateBreadcrumb,
    'dow-corp/components/ui/GlobalHeaderNavigationBar':
      GlobalHeaderNavigationBar,
    'dow-corp/components/ui/InPageSearchBar': InPageSearchBar,
    'dow-corp/components/ui/eventDetails': EventDetails,
    'dow-corp/components/commerce/corp-subsite-search': CorpSubsiteSearch,
    'dow-corp/components/ui/news-and-events-band': NewsAndEventsBand,
    'dow-corp/components/ui/videoModal': VideoModal,
    'dow-corp/components/ui/GlobalHeaderSearchBar': GlobalHeaderSearchBar,
    'dow-corp/components/ui/GlobalHeaderNavigationBar':
      GlobalHeaderLanguageSwitcher,
    'dow-corp/components/ui/solutionBlock': SolutionBlock,
    'dow-corp/components/ui/location-finder': LocationFinder,
    'dow-corp/components/ui/country-finder': CountryFinder,
    'dow-corp/components/ui/tag-links': TagLinks,
    'dow-corp/components/ui/image-band': ImageBand,
    'dow-corp/components/ui/videoBand': VideoBand,
    'dow-corp/components/ui/iconBand': IconBand,
    'dow-corp/components/ui/blogAuthor': BlogAuthor,
    'dow-corp/components/ui/featureHighlight': FeatureHighlight,
    'dow-corp/components/ui/MediaTileBand': MediaTileBand,
    'dow-corp/components/ui/corporateCarousel': CorporateCarousel,
    'dow-corp/components/commerce/triverconserv-contact-form':
      TriverconservContactForm,
    'dow-corp/components/commerce/univation-contact-form': UnivationContactForm,
    'dow-corp//components/commerce/forms': ContactUsForm,
    'dow-corp/components/ui/tile-band': TileBand,
    'dow-corp/components/ui/related-article': RelatedArticle,
    'dow-corp/components/ui/threeUpImageBand': ThreeUpImageBand,
    'dow-corp/components/ui/articleFilter': ArticleFilter,
    'dow-corp/components/ui/link-list': LinkList,
    'dow-corp/components/ui/shortEventList': ShortEventList,
    'dow-corp/components/ui/globalHeaderNavigationTray': GlobalHeaderNavigationTray,
    'dow-corp/components/ui/resourceFinder': ResourceFinder,
    'dow-corp/components/ui/verticalSpacer':VerticalSpacer,
    'dow-corp/components/ui/GlobalHeader': GlobalHeader,
    'dow-corp/components/other-sites/GlobalHeader': GlobalHeader,
    'dow-corp/components/ui/eventDetails': EventDetails,
    'dow-corp/components/commerce/docCenter': DocCenter,
    'dow-corp/components/commerce/corp-subsite-search': CorpSubsiteSearch,
    'dow-corp/components/ui/jobsFeed': JobsFeed
  },
  created() {
    this.$store.dispatch(
      "browser/setLanguageCode",
      document.documentElement.lang
    );

    this.$store.dispatch("browser/setGeoLocationInSession", {});
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push((dl) => {
      dl.addEventListener("adobeDataLayer:change", (event) =>
        this.$store.dispatch("dataLayerUpdate", event)
      );
      dl.addEventListener("adobeDataLayer:event", (event) =>
        this.$store.dispatch("dataLayerEvent", event)
      );
    });
    this.$store.dispatch('page/load')
  },
};
</script>

<style lang="scss">
@import "./main/webpack/site/base";
@import "./site/base";
</style>
