import axios from 'axios'
import settings from '../../../../../../../../dow-platform/ui.frontend/src/store/settings.js'
import store from '../../../../../store'

export function DOMLoaded() {
    var root = document.getElementById("search");
    if (root != null) {
        Coveo.$$(root).on('afterInitialization', function () {
            var coveoBtn = document.querySelector('.magic-box-clear')
            coveoBtn.addEventListener("click", function (event) {
                Coveo.state(root, 'q', '')
                Coveo.executeQuery(root)
            });
        });
    
        //Coveo.SearchEndpoint.configureSampleEndpointV2();
        store.dispatch('search/fetchSearchToken', {
            hub: "CorporateDowComEventsFinder"
        })
        .then(result => {
            Coveo.SearchEndpoint.configureCloudV2Endpoint(result.data.org, result.data.token);
            root = document.getElementById("search");
            var totalResultsCount = 0;
    
            Coveo.$$(root).on("preprocessResults", function (e, args) {
            totalResultsCount = args.results.totalCount
            const TitleMessage = Granite.I18n.get('insert.query');
            document.querySelector('#search input[form="coveo-dummy-form"]').setAttribute("title", TitleMessage );
            })
    
            Coveo.$$(root).on("newResultsDisplayed", function (e, args) {
            var paginationButtons = document.querySelectorAll(".coveo-results-per-page-list-item-text");
            var searchUI = document.querySelector('.corpEvents')
            for (var i = 0; i < paginationButtons.length; i++) {
                var cssClass = 'hidden-' + paginationButtons[i].textContent
                if (totalResultsCount > parseInt(paginationButtons[i].textContent)) {
                searchUI.classList.remove(cssClass)
                } else if (totalResultsCount < parseInt(paginationButtons[i].textContent)) {
                searchUI.classList.add(cssClass)
                }
            }
            })
    
            Coveo.init(root);
            //_dcc.actions?.search?.onCookieChangeSetAnalytics(root);
        })
    }
}