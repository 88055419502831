<template>
  <Teleport
    :to="
      '#mega__inPageGlobalSearch__' +
      this.cmpId.replace('inPageGlobalSearch-', '') +
      ' #inPage-searchbox-headless'
    "
  >
    <SearchBox
      :parentAuthorableElementId="
        '#mega__inPageGlobalSearch__' +
        this.cmpId.replace('inPageGlobalSearch-', '')
      "
    >
    </SearchBox>
  </Teleport>
</template>
<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import SearchBox from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/blocks/input-elements/search-box'
export default {
  extends: CoreComponent,
  components: {
    SearchBox
  }
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/commerce/inPageGlobalSearch/css/inPageGlobalSearch.scss';
@import './GlobalHeaderSearchBar.scss';
</style>
