import ApiClient from '../plugins/AxiosPlugin'

export default {
    namespaced: true,
    state: {
        activeDocument: null,
        activeDocumentPanel: false
    },
    mutations: {
        setActiveDocument: function (state, payload) {
            state.activeDocument = payload
        },
        setActivePanel: function (state, payload) {
            state.activeDocumentPanel = payload
        }
    },
    actions: {
        fetchDocumentDetails
    }
}

async function fetchDocumentDetails(store, payload) {
    let assetUrl = ''
    if (payload.commonsource == "Documents") {
        if(
            payload.dow_contentformat === "Video" ||
            payload.dow_contentformat === "Audio" ||
            payload.dow_contentformat === "External Resource" ||
            payload.dow_contentformat === "Tradeshow - Conference" ||
            payload.dow_contentformat === "Webinar"
        ) {
            assetUrl = payload.dow_dam_path
        } else if (payload.dow_url.includes("content/dam/internal")) {
            assetUrl =  payload.dow_url 
        }  else {
            let dowUrl = payload.dow_url;
            var documentsIndex = dowUrl.indexOf("/documents");

            if(documentsIndex > -1) {
                dowUrl = dowUrl.substr(documentsIndex)
            }

            let itemUrl = new URL(payload.dow_url)
            assetUrl = `/content/dam/dcc${itemUrl.pathname}`
        }
    }

    try {
        const docData = await ApiClient.get(
            store.rootState.settings.get_asset_details,
            {
                params: {
                    assetPath: assetUrl
                }
            }
        )

        if (docData.data) {
            store.commit('setActiveDocument', {coveoData: payload, dowData: docData.data})
        }
    } catch (error) {
        console.log('Error in fetching document details >>>', error)
    }

    store.commit('setActivePanel', true)
}
