<script>
import CoreComponentVue from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponentVue,

  mounted() {
    console.log('Testing blog author')
  }
}
</script>

<style lang="scss">
@import './blogAuthor.scss';
</style>
