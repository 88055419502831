<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
    extends: CoreComponent,
    methods: {
        tick() {
            const now = new Date();
            const total = this.endTime.getTime() - now.getTime();
            const seconds = Math.floor((total/1000) % 60);
            const minutes = Math.floor((total/1000/60) % 60);
            const hours = Math.floor((total/(1000*60*60)) % 24);
            const days = Math.floor((total/(1000*60*60*24)));

            this.daysEl.innerText = days;
            this.hoursEl.innerText = hours;
            this.minutesEl.innerText = minutes;
            this.secondsEl.innerText = seconds;

            if (total >= 0) {
                if (!this.clockVisible) {
                    this.clock.classList.remove("cmp-countdown-clock--hidden"); 
                    this.clockVisible = true;
                }
                setTimeout(() => { this.tick() }, 1000);
            } else {
                this.clock.classList.add("cmp-countdown-clock--hidden");
                this.message.classList.remove("cmp-countdown-clock--hidden");
            }

        }
    },
    mounted() {
        this.endTime = new Date(this.cmpEl.dataset.countdownEnd);
        this.daysEl = this.cmpEl.querySelector(".cmp-countdown-clock__days .cmp-countdown-clock__value");
        this.hoursEl = this.cmpEl.querySelector(".cmp-countdown-clock__hours .cmp-countdown-clock__value");
        this.minutesEl = this.cmpEl.querySelector(".cmp-countdown-clock__minutes .cmp-countdown-clock__value");
        this.secondsEl = this.cmpEl.querySelector(".cmp-countdown-clock__seconds .cmp-countdown-clock__value");
        this.clock = this.cmpEl.querySelector(".cmp-countdown-clock__container");
        this.message = this.cmpEl.querySelector(".cmp-countdown-clock__message");
        this.clockVisible = false;
        this.tick();

    }
}
</script>

<style lang="scss">
@import './countdown-clock.scss';
</style>
