<script>
import PlatformText from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/text/text.vue";

export default {
  extends: PlatformText,
};
</script>

<style lang="scss">
@import "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/text/text.scss";
@import "./text.scss";
</style>
