<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
  extends: CoreComponent,

  data() {
    return {
      languageListActive: false,
    };
  },

  mounted() {
    const urlPath = window.location.href
    const urlSegments = urlPath.split('/')
    
    const languageDropdown = this.cmpEl.querySelector(
      "#language-switcher_dropdown"
    );

    const languageDropdownMobile = document.querySelector('#language-switcher_mobile_dropdown')
    const currentLanguage = this.cmpEl.querySelector(".block__filter-by");
    const currentLanguageMobile = document.querySelector(".mobile_block__filter-by");
    const languageListitems = document.querySelectorAll(".language-list-item");
    const languageIconWrappers = document.querySelectorAll(".icon-language-wrapper");
    const languageIconLetters = document.querySelectorAll(".icon-language-letter");
    const arrowIcon = document.querySelector(".icon-triangle");

    currentLanguage?.addEventListener("click", () => {
      if (languageDropdown.classList.contains("show-list")) {
        languageDropdown.classList.remove("show-list");
        currentLanguage.classList.remove("active");
        languageIconWrappers.forEach(element => {
          element.classList.remove("red-stroke")
        });
        languageIconLetters.forEach(element => {
          element.classList.remove("red-fill")
        });
        arrowIcon.classList.remove("rotate", "red-stroke", "red-fill");
      } else {
        languageDropdown.classList.add("show-list");
        currentLanguage.classList.add("active");
        languageIconWrappers.forEach(element => {
          element.classList.add("red-stroke")
        });
        languageIconLetters.forEach(element => {
          element.classList.add("red-fill")
        });
        arrowIcon.classList.add("rotate", "red-stroke", "red-fill");
      }
    });
    currentLanguageMobile?.addEventListener("click", () => {
      if (languageDropdownMobile.classList.contains("show-list")) {
            languageDropdownMobile.classList.remove("show-list");
            currentLanguageMobile.classList.remove("active");
            languageIconWrappers.forEach(element => {
              element.classList.remove("red-stroke")
            });
            languageIconLetters.forEach(element => {
              element.classList.remove("red-fill")
            });
          } else {
            languageDropdownMobile.classList.add("show-list");
            currentLanguageMobile.classList.add("active");
            languageIconWrappers.forEach(element => {
              element.classList.add("red-stroke")
            });
            languageIconLetters.forEach(element => {
              element.classList.add("red-fill")
            });
          }
        });
    },
};
</script>

<style lang="scss">
@import "./GlobalHeaderLanguageSwitcher.scss";
</style>

