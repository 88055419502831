<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<template>
  <Teleport to=".cmp-shareOnSocial-share-content">
    <div class="cmp-shareOnSocial-share-content-container">
      <a @click.prevent="this.toggleShareSheet" class="share-content-btn">
      <p class="share-content-btn-text">
        Share this article
      </p>
      <div class="share-content-btn-icon">
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.4999 3.99995C19.4999 5.57056 18.2268 6.8438 16.6562 6.8438C15.0856 6.8438 13.8125 5.57056 13.8125 3.99995C13.8125 2.4295 15.0856 1.15625 16.6562 1.15625C18.2268 1.15625 19.4999 2.4295 19.4999 3.99995Z" fill="#00B2A9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1523 3.99995C13.1523 5.93024 14.722 7.50005 16.6523 7.50005C18.5826 7.50005 20.1522 5.93024 20.1522 3.99995C20.1522 2.06981 18.5826 0.5 16.6523 0.5C14.722 0.5 13.1523 2.06981 13.1523 3.99995ZM14.4648 3.99992C14.4648 2.79429 15.4457 1.81248 16.6523 1.81248C17.8589 1.81248 18.8397 2.79429 18.8397 3.99992C18.8397 5.20572 17.8589 6.18753 16.6523 6.18753C15.4457 6.18753 14.4648 5.20572 14.4648 3.99992Z" fill="#00B2A9"/>
          <path d="M19.4999 18.0001C19.4999 19.5706 18.2268 20.8438 16.6562 20.8438C15.0856 20.8438 13.8125 19.5706 13.8125 18.0001C13.8125 16.4295 15.0856 15.1562 16.6562 15.1562C18.2268 15.1562 19.4999 16.4295 19.4999 18.0001Z" fill="#00B2A9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1523 18.0001C13.1523 19.9302 14.722 21.5001 16.6523 21.5001C18.5826 21.5001 20.1522 19.9302 20.1522 18.0001C20.1522 16.0698 18.5826 14.5 16.6523 14.5C14.722 14.5 13.1523 16.0698 13.1523 18.0001ZM14.4648 18.0001C14.4648 16.7943 15.4457 15.8125 16.6523 15.8125C17.8589 15.8125 18.8397 16.7943 18.8397 18.0001C18.8397 19.2057 17.8589 20.1876 16.6523 20.1876C15.4457 20.1876 14.4648 19.2057 14.4648 18.0001Z" fill="#00B2A9"/>
          <path d="M7.24615 10.9999C7.24615 12.5706 5.9729 13.8436 4.40229 13.8436C2.83184 13.8436 1.55859 12.5706 1.55859 10.9999C1.55859 9.42934 2.83184 8.15625 4.40229 8.15625C5.9729 8.15625 7.24615 9.42934 7.24615 10.9999Z" fill="#00B2A9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.902344 10.9999C0.902344 12.9302 2.47215 14.4999 4.40229 14.4999C6.33259 14.4999 7.9024 12.9302 7.9024 10.9999C7.9024 9.06965 6.33259 7.5 4.40229 7.5C2.47215 7.5 0.902344 9.06965 0.902344 10.9999ZM2.21484 10.9999C2.21484 9.79411 3.19569 8.81246 4.40229 8.81246C5.60905 8.81246 6.5899 9.79411 6.5899 10.9999C6.5899 12.2057 5.60905 13.1873 4.40229 13.1873C3.19569 13.1873 2.21484 12.2057 2.21484 10.9999Z" fill="#00B2A9"/>
          <path d="M6.47353 10.583C6.16896 10.583 5.87319 10.4245 5.71218 10.1411C5.47329 9.72198 5.62037 9.18733 6.0395 8.94749L14.1585 4.31881C14.5776 4.07817 15.1123 4.22524 15.3521 4.64598C15.591 5.0651 15.4439 5.59975 15.0248 5.83959L6.90563 10.4683C6.76913 10.5461 6.62045 10.583 6.47353 10.583Z" fill="#00B2A9"/>
          <path d="M14.5908 17.8016C14.4437 17.8016 14.2951 17.7647 14.1586 17.6868L6.03938 13.0582C5.62025 12.8193 5.47333 12.2846 5.71222 11.8646C5.95014 11.4446 6.48559 11.2967 6.90567 11.5374L15.0248 16.1661C15.444 16.4049 15.5909 16.9396 15.352 17.3597C15.1902 17.6431 14.8944 17.8016 14.5908 17.8016Z" fill="#00B2A9"/>
        </svg>
      </div>      
      </a>


      <!-- <a @click.prevent="this.toggleShareSheet" class="share-content-btn">${'share.comp' @ i18n}</a> -->
  
      <div class="share-content-wrapper">
        <div class="share-content-modal">
          <div class="share-content-triangle" v-show="this.showShareSheet">
            <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.40234 0L0.402344 9H18.4023L9.40234 0Z" fill="#415364" stroke="#415364"/>
            </svg>
          </div>
          <div class="share-content-container" v-show="this.showShareSheet">
            <a :href="linkedInURL" class="social-icons li-share-btn" title="LinkedIn Share" target="_blank">
              <img src="/content/dam/dcc/images/footer/icons/linkedin-logo.svg" />
            </a>
            <a :href="xUrl" class="social-icons x-share-btn" title="Twitter Share" target="_blank">
              <img src="/content/dam/dcc/images/footer/icons/twitter-logo.svg" />
            </a>
            <a :href="facebookUrl" title="Facebook Share" class="social-icons fb-share-btn" target="_blank">
              <img src="/content/dam/dcc/images/footer/icons/facebook-logo.svg" />
            </a>
          </div>         
        </div>
        </div>
    </div>
  </Teleport>
</template>

<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import ParsleyJs from 'parsleyjs'

export default {
  extends: CoreComponent,
  data() {
    return {
      showShareSheet: false
    }
  },
  methods: {
    toggleShareSheet() {
      this.showShareSheet = !this.showShareSheet
    }
  },
  computed: {
    linkedInURL: () => {
      return (
        'https://www.linkedin.com/shareArticle?mini=true&url=' +
        window.location.origin +
        window.location.pathname
      )
    },
    xUrl: () => {
      return (
        'https://twitter.com/intent/tweet?url=' +
        window.location.origin +
        window.location.pathname
      )
    },
    facebookUrl: () => {
      return (
        'https://www.facebook.com/sharer/sharer.php?u=' +
        window.location.origin +
        window.location.pathname
      )
    }
  }
}
</script>

<style lang="scss">
@import './shareOnSocial.scss';
</style>