<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import { DOMLoaded } from './js/corpEvents.js'

export default {
    extends: CoreComponent,
    created() {
        DOMLoaded()
    }
}
</script>

<style lang="scss">
@import './css/corpEvents.scss';
@import '../../../../site/coveo-overrides';
</style>
