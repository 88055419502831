<script>
import CoreComponent from '../CoreComponent.vue'

export default {
    extends: CoreComponent
}
</script>

<style lang="scss">
@import './title.scss';
</style>
