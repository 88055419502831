<template>
  <Teleport :to="leftArrow">
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.1091 12L14.0004 21.5L43.0001 21.6678L42.9996 26H13.8325L24.1091 36H18.8442L6.00021 24L18.8442 12H24.1091Z"
          fill="#E80033"
        />
      </svg>
    </span>
  </Teleport>
  <Teleport :to="rightArrow">
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.8909 12L34.9996 21.5L5.99988 21.6678L6.00037 26H35.1675L24.8909 36H30.1558L42.9998 24L30.1558 12H24.8909Z"
          fill="#E80033"
        />
      </svg>
    </span>
  </Teleport>
</template>

<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";
import { carouselClicksTracking } from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/ACDLHandler";

export default {
  extends: CoreComponent,

  computed: {
    leftArrow() {
      return this.cmpEl.querySelector(".cmp-carousel__action--previous");
    },

    rightArrow() {
      return this.cmpEl.querySelector(".cmp-carousel__action--next");
    },
  },

  mounted() {
    const carouselwrapper = this.cmpEl.querySelector(".cmp-carousel__content");

    const leftnav = this.cmpEl
      .querySelector(".cmp-carousel__action--previous")
      .querySelector(".cmp-carousel__action-text");
    leftnav.remove();

    const rightnav = this.cmpEl
      .querySelector(".cmp-carousel__action--next")
      .querySelector(".cmp-carousel__action-text");
    rightnav.remove();

    const carouselItems = this.cmpEl.querySelectorAll(".cmp-carousel__item");

    let isBanner = this.cmpEl.classList.contains("cmp-carousel--banner");
    let bgImage = this.cmpEl.dataset.backgroundImage;
    let maxHeightItem = [];

    carouselItems.forEach((item) => {
      maxHeightItem.push(item.offsetHeight);
    });
    carouselwrapper.style.height = Math.max(...maxHeightItem) + "px";

    if (isBanner) {
      let columnBands = this.cmpEl.querySelectorAll(".cmp-columnBand__container");
      let bandHeight = carouselwrapper.style.height
      columnBands.forEach(band => {
        band.style.height = bandHeight;
      }); 
    }

    if (bgImage) {
      let target = this.cmpEl;
      if (!isBanner) {
        target = target.parentElement;
      } 
      target.style.backgroundImage = `url(${bgImage})`;
    }

    this.leftArrow.addEventListener("click", () => {
      //Add Carousel Analytics
      indicators.forEach((indicator, index) => {
        if (indicator.classList.contains('cmp-carousel__indicator--active')) {
          if(index <= 0) {
            carouselClicksTracking(this.cmpId, "Corporate Carousel", indicators.length - 1);
          }
          else {
            carouselClicksTracking(this.cmpId, "Corporate Carousel", index - 1);
          }
        } 
      });

      carouselItems.forEach((item) => {
        item.style.animation = "";
        item.style.visibility = "hidden";
      });

      setTimeout(() => {
        this.cmpEl.querySelector(
          ".cmp-carousel__item--active"
        ).style.visibility = "visible";
        this.cmpEl.querySelector(
          ".cmp-carousel__item--active"
        ).style.animation = "moveRightActive 0.7s";
      }, 300);
    });
    this.rightArrow.addEventListener("click", () => {
      //Add Carousel Analytics
      indicators.forEach((indicator, index) => {
        if (indicator.classList.contains('cmp-carousel__indicator--active')) {
          if(index >= indicators.length - 1) {
            carouselClicksTracking(this.cmpId, "Corporate Carousel", 0);
          }
          else {
            carouselClicksTracking(this.cmpId, "Corporate Carousel", index + 1);
          }
        } 
      });
      
      carouselItems.forEach((item) => {
        item.style.animation = "";
        item.style.visibility = "hidden";
      });

      setTimeout(() => {
        this.cmpEl.querySelector(
          ".cmp-carousel__item--active"
        ).style.visibility = "visible";
        this.cmpEl.querySelector(
          ".cmp-carousel__item--active"
        ).style.animation = "moveLeftActive 0.7s";
      }, 300);
    });

    const indicators = this.cmpEl.querySelectorAll('.cmp-carousel__indicators .cmp-carousel__indicator')
    indicators.forEach((indicator, index) => {
      indicator.addEventListener("click", () => {
        //Add Carousel Analytics
      carouselClicksTracking(this.cmpId, "Corporate Carousel", index);

        carouselItems.forEach((item) => {
          item.style.removeProperty("visibility");
        });
      })
    })
  },
};
</script>

<style lang="scss">
@import "./corporateCarousel.scss";
</style>
