import { createStore } from 'vuex'

import assets from '@platform/store/assets'
import product from '@platform/store/product'
import loading from '@platform/store/loading'
import components from '@platform/store/components'
import browser from '@platform/store/browser'
import user from '@platform/store/user'
import search from '@platform/store/search'
import { featureFlag } from '@platform/store/feature-flag'
import page from '@platform/store/page'
import settings from '@platform/store/settings'
import form from '@platform/store/form'
import documents from '@platform/store/documents'

export default createStore({
  actions: {
    dataLayerUpdate({ commit }, { component }) {
      if (component) commit('components/update', component)
    },
    dataLayerEvent({ dispatch }, event) {
      if (event?.event) dispatch(event.event, event)
    },
    trackCmpInteraction,
    trackLink
  },
  modules: {
    assets,
    loading,
    components,
    browser,
    user,
    search,
    featureFlag,
    page,
    settings,
    form,
    documents,
    product
  }
})

function trackCmpInteraction(context, { event, cmpId, data }) {
  window.adobeDataLayer = window.adobeDataLayer || []
  window.adobeDataLayer.push({
    event,
    eventInfo: {
      path: `component.${cmpId}`
    },
    ...data
  })
}

function trackLink(context, link) {
  window.adobeDataLayer = window.adobeDataLayer || []

  window.adobeDataLayer.push({
      event: 'cmp:click',
      eventInfo: {
          path: link.name
      },
      link: {
          linkName: link.name,
          linkPosition: link.position || link.component,
          linkPage: context.state.page.pageInfo.pageName
      }
  })
}
