<template>
    <span role="presentation" :data-cmp-id="cmpId"></span>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    ACDLHandler,
    getEventName,
    getComponentPath,
    updateComponentDataLayer
} from './ACDLHandler'

export const AEM_GRID_PREFIX = 'aem-GridColumn--'
// dataLayer properties
export const CMP_TITLE = 'dc:title'
export const CMP_DESCRIPTION = 'dc:description'
export const CMP_LINK_URL = 'xdm:linkURL'
export const CMP_LANGUAGE = 'xdm:language'
export const CMP_TEXT = 'xdm:text'
export const CMP_TAGS = 'xdm:tags'
export const CMP_EMBEDDABLE_PROPS = 'embeddableProperties'

export default {
    computed: {
        cmpId() {
            return this.$.vnode.key
        },
        cmpEl() {
            return getComponentEl(this.cmpId)
        },
        cmpStyles() {
            return getComponentStyles(this.cmpEl)
        },
        cmpItems() {
            return this['components/items'](this.cmpId)
        },
        cmpItemsData() {
            return this.cmpItems.map((itemId) =>
                this['components/dataLayer'](itemId)
            )
        },
        cmpEmbeddableData() {
            return this['components/dataLayer'](this.cmpId)[
                CMP_EMBEDDABLE_PROPS
            ]
        },
        cmpTitle: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_TITLE]
            },
            set(title) {
                this.cmpUpdate({ [CMP_TITLE]: title })
            }
        },
        cmpDescription: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_DESCRIPTION]
            },
            set(description) {
                this.cmpUpdate({ [CMP_DESCRIPTION]: description })
            }
        },
        cmpLinkURL: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_LINK_URL]
            },
            set(linkURL) {
                this.cmpUpdate({ [CMP_LINK_URL]: linkURL })
            }
        },
        cmpLanguage: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_LANGUAGE]
            },
            set(language) {
                this.cmpUpdate({ [CMP_LANGUAGE]: language })
            }
        },
        cmpText: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_TEXT]
            },
            set(text) {
                this.cmpUpdate({ [CMP_TEXT]: text })
            }
        },
        cmpTags: {
            get() {
                return this['components/dataLayer'](this.cmpId)[CMP_TAGS]
            },
            set(tags) {
                this.cmpUpdate({ [CMP_TAGS]: tags })
            }
        },
        cmpDataLayer() {
            return this['components/dataLayerJSON'](this.cmpId)
        },
        [`components/dataLayer`]() {
            return (cmpId) => this.$store.getters['components/dataLayer'](cmpId)
        },
        [`components/dataLayerJSON`]() {
            return (cmpId) =>
                this.$store.getters['components/dataLayerJSON'](cmpId)
        },
        [`components/items`]() {
            return (cmpId) => this.$store.getters['components/items'](cmpId)
        }
    },
    methods: {
        cmpUpdate(updates) {
            updateComponentDataLayer(this.cmpId, updates)
        },
        cmpEvent(event) {
            window.adobeDataLayer.push({
                event: getEventName(event),
                originalEvent: event,
                eventInfo: { path: getComponentPath(this.cmpId) }
            })
        },
        cmpOn(event, cb, once, cmpId) {
            let listener = new ACDLHandler(event, cmpId || this.cmpId, cb, once)
            listener.on()
        },
        cmpOff(event, cb, cmpId) {
            let matcher = new ACDLHandler(event, cmpId || this.cmpId, cb)
            let listener = ACDLHandler.registry.find((listener) => {
                return listener.id == matcher.id && cb == listener.cb
            })
            if (listener) listener.off()
        }
    },
    watch: {
        cmpDataLayer(dlJSON) {
            this.cmpEl.dataset.cmpDataLayer = `{"${this.cmpId}":${dlJSON}}`
        }
    }
}

export const cmpParents = {}

export function getComponentStyles(cmpEl) {
    const classList = cmpParents[cmpEl.id]?.classList
    return Array.from(classList || []).reduce((styles, cls) => {
        const prefix = `${cmpEl.className.split(' ')[0]}--`
        if (cls.startsWith(prefix)) styles.push(cls.replace(prefix, ''))
        else if (cls.startsWith(AEM_GRID_PREFIX)) styles.push(cls)
        return styles
    }, [])
}

export function getComponentEl(cmpId) {
    const el =
        document.getElementById(cmpId) ||
        document.querySelector(`[data-cmp-data-layer*=${cmpId}]`)
    cmpParents[cmpId] = el.parentElement
    return el
}
</script>
