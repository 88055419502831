<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
  extends: CoreComponent,
  data() {
    return {
      videoList: [],
    };
  },
  mounted() {
    document
      .querySelectorAll(".cmp-video-modal")
      .forEach((videoModalComponent) => {
        const videoListSrc = videoModalComponent
          .getAttribute("data-video-src")
          .substring(
            videoModalComponent.getAttribute("data-video-src").indexOf("[") + 1,
            videoModalComponent.getAttribute("data-video-src").indexOf("]")
          )
          .split(", ");
        videoListSrc.forEach((videoSrc) => {
          this.videoList.push(videoSrc);
        });
      });

    document
      .querySelectorAll('a[href*="youtube.com"], a[href*="videos.dow.com"]')
      .forEach((videoFoundInPage) => {
        if (this.videoList.indexOf(videoFoundInPage.href) > -1) {
          videoFoundInPage.addEventListener("click", this.openModal);
        }
      });

    this.cmpEl.querySelectorAll(".cmp-video-modal__close").forEach((item) => {
      item.addEventListener("click", this.closeModal);
    });
    document.addEventListener("click", (event) => {
      if (
        this.cmpEl.querySelector(".cmp-video-modal--open") &&
        !this.cmpEl
          .querySelector(".cmp-video-modal__content")
          .contains(event.target) &&
        !event.target.closest("a")
      ) {
        this.closeModal();
      }
    });
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        if (this.cmpEl.querySelector(".cmp-video-modal--open")) {
          this.closeModal();
        }
      }
    });
  },
  methods: {
    openModal(event) {
      const iFrame = document.createElement("iframe");
      iFrame.src = event.target.closest("a").href;
      iFrame.setAttribute("allow", "autoplay");
      iFrame.setAttribute("allowfullscreen", true);
      iFrame.setAttribute("autoplay", true);
      iFrame.setAttribute("frameborder", "0");

      document
        .querySelectorAll(".cmp-video-modal")
        .forEach((videoModalComponent) => {
          if (
            videoModalComponent
              .getAttribute("data-video-src")
              .indexOf(event.target.closest("a").href) > -1 &&
            !videoModalComponent
              .querySelector(".cmp-video-modal__video")
              .querySelector("iframe")
          ) {
            videoModalComponent
              .querySelector(".cmp-video-modal__video")
              .appendChild(iFrame);
            videoModalComponent
              .querySelector(".cmp-video-modal__wrapper")
              .classList.add("cmp-video-modal--open");
          }
        });

      document.body.style.overflow = "hidden";
      event.preventDefault();
    },
    closeModal() {
      this.cmpEl
        .querySelector(".cmp-video-modal--open")
        .querySelector("iframe")
        .remove();
      this.cmpEl
        .querySelector(".cmp-video-modal__wrapper")
        .classList.remove("cmp-video-modal--open");
      document.body.style.overflow = "unset";
    },
  },
};
</script>

<style lang="scss">
@import "./videoModal.scss";
</style>
