<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'
import { detectFileDownloadLink } from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/ACDLHandler'

export default {
  extends: CoreComponent,
  mounted() {
    //File Download Tracking
    detectFileDownloadLink(this.cmpEl)
  },
  created (){

        var button = this.cmpEl
        button.addEventListener("click", 
        function(){
            this.$store.dispatch('trackLink', {
              name: button.ariaLabel || button.innerText || 'undefined',
              position: button.getAttribute("parentId") ||  button.id
            })
        }.bind(this)
        )
        
    }
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/button/button.scss';
@import './cta.scss';
@import './cta-dorinco.scss';
@import './cta-midland.scss';
@import './cta-tittabawassee.scss';
@import './cta-univation.scss';
@import './cta-rohm-haas.scss';
@import './cta-union-carbide-corporation.scss';
@import './cta-bhopal.scss';
</style>
