import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";
import './fake.scss';

// ensure there is a wrapper for the app
let appWrapper = document.getElementById("app-corp");
if (!appWrapper) {
  appWrapper = document.createElement("div");
  appWrapper.id = "app-corp";
  document.querySelector("body").appendChild(appWrapper);
}

const DcApp = createApp(App);
DcApp.use(store);
DcApp.use(ElementPlus);
DcApp.mount("#app-corp");
window.store = store
// axios
//     .get('/libs/granite/csrf/token.json')
//     .then(function (response) {
//         axios.defaults.headers.common['CSRF-Token'] = response.data.token
//     })
//     .catch(function (error) {
//         console.log(error)
//     })
