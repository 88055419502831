<template>
    <Teleport to="[data-benefits-calculator-form]">
        <div class="retiree-premium-calculator section" data-action="retireePremiumCalculator">
            <div id="calc-header">
            </div>
            <div id="calc-body" class="cl-form-wrapper">
                <div class="retiree-calc-selectors">
                    <div id="coverage-level-container" class="field">
                        <label>Coverage Level
                        <select id="coverage-level" class="field-container" data-next-select="#eligibility"><option value="" selected>Select Coverage Level</option></select>
                        </label>
                        <div class="clearfix"></div>
                    </div>
                    <div id="eligibility-container" class="field">
                        <label>Eligibility
                        <select id="eligibility" class="field-container" data-next-select="#medical-plan" disabled><option value="">Select Eligibility</option></select>
                        </label>
                        <div class="clearfix"></div>
                    </div>
                    <div id="medical-plan-container" class="field">
                        <label>Medical Plan
                        <select id="medical-plan" class="field-container" data-next-select="#years-service" disabled><option value="">Select Medical Plan</option></select>
                        </label>
                        <div class="clearfix"></div>
                    </div>
                    <div id="years-service-container" class="field">
                        <label>Years of Service
                        <select id="years-service" class="field-container" data-next-select="#retiree-cost" disabled><option value="">Select Years of Service</option></select>
                        </label>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div id="retiree-cost-container" class="field">
                    <p>Monthly Retiree Cost</p>
                    <div id="retiree-cost" class="field-container">____</div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,
  data() {
      return {
          selects: [],
          selectData : null
      }
  },
  mounted: function () {
    console.log("init! benefits calculator")

    var path = this.cmpEl.dataset.path

    var selectTags = this.cmpEl.querySelectorAll("#calc-body select")
    this.selects = selectTags;

    var self = this;

    var xhr = new XMLHttpRequest();

    xhr.onload = function() {
        var xml = xhr.responseXML;
        self.parseXML(xml)
        self.generateSelect(selectTags[0], self.selectData);
        self.bindEvents();
    }

    xhr.onerror = function() {
        console.log("Unable to load benefit data.");
    }


    xhr.open("GET", path);
    xhr.responseType = "document";
    xhr.send();

    console.log("End! benefits calculator ")
  },
  methods: {
      parseXML: function(xml) {
          var json = {};
          var rows = xml.querySelectorAll("Row");
          for (var i=0, j=rows.length; i<j; i++) {

              // GET KEYS
              var items = rows[i].children;
              var coverageKey = items[0].textContent;
              var eligibilityKey = items[1].textContent;
              var planKey = items[2].textContent;
              var yearsKey = items[3].textContent;
              var cost = items[4].textContent;

              // GET EXISTING ENTRY OR MAKE ONE
              var coverage = json[coverageKey] = json[coverageKey] || {};
              var eligibliity = coverage[eligibilityKey] = coverage[eligibilityKey] || {};
              var plan = eligibliity[planKey] = eligibliity[planKey] || {};
              plan[yearsKey] = cost;

          }
          this.selectData = json
      },
      generateSelect: function(select, selectData) {
          var placeholder = select.querySelector("option:first-child").outerHTML;
          select.innerHTML = placeholder;

          for (var key in selectData) {
              var option = document.createElement("option");
              option.value = key;
              option.innerText = key;
              select.appendChild(option)
          }
      },
      getOptions: function() {
          let options = this.selectData;
          for (var i=0, j=this.selects.length; i<j; i++) {
              let select = this.selects[i];
              if (select.disabled) { break; }
              options = options[select.value];
          }
          return options;
      },
      disableDependents: function(parent) {
          let dependent = this.cmpEl.querySelector(parent.dataset.nextSelect)
          if (dependent) {
              dependent.value = "";
              dependent.disabled = true;
              this.disableDependents(dependent)
          }
      },
      bindEvents: function() {
        var self = this;
        for (var i=0, j=this.selects.length; i<j; i++) {
          var select = this.selects[i];
          select.addEventListener("change", function(e) {
              self.disableDependents(e.target);
              self.cmpEl.querySelector("#retiree-cost").innerText = "____";
              var options = self.getOptions();
              if (typeof options === "object") {
                  var next = self.cmpEl.querySelector(e.target.dataset.nextSelect)
                  self.generateSelect(next, options)
                  next.disabled = false;
              } else {
                  self.cmpEl.querySelector("#retiree-cost-container").classList.remove("retiree-cost-container--pending");
                  self.cmpEl.querySelector("#retiree-cost").innerText = "$" + options;
              }

          })
        }
        }
  }

}
</script>

<style lang="scss">
@import './benefits-calculator.scss';
</style>
