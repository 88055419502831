<script>
import PlatformTabs from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/tabs/Tabs.vue'

export default {
  extends: PlatformTabs
}
</script>

<style lang="scss">
@import './Tabs.scss';
</style>
