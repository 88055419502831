<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent
}
</script>

<style lang="scss">
@import './minimal-footer.scss';
</style>
