<script>
import PlatfromAccordion from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/accordion/accordion.vue';


export default {
  extends: PlatfromAccordion,
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/accordion/accordion.scss';
@import './accordion.scss';
</style>
