<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,
  data() {
    return {
      isDesignView: false,
    };
  },
  methods: {
    checkImageURL() {

      // CHECK IF IN DESIGN VIEW OR NOT FROM THE SLY-TEST
      if (document.querySelectorAll(".cmp-columnBand__container.design-view").length > 0) {
        this.isDesignView = true;
      }

      // IF NOT IN DESIGN VIEW - PROCESS THE IMAGE BACKGROUNDS - IF THEY EXIST
      if (!this.isDesignView) {

        var els = document.querySelectorAll(".columnBand"), i;

        for (i = 0; i < els.length; ++i) {
          
          let imageURL = null;

          if (els[i].querySelectorAll(".cmp-columnBand__content .cmp-image").length > 0) {
            imageURL = els[i].querySelector(".cmp-columnBand__content .cmp-image img").src;
            els[i].querySelector(".cmp-columnBand__content .cmp-image").remove();
          }

          if (imageURL !== null) {
            els[i].querySelector(".cmp-columnBand__content").style.setProperty('background-image', `url(${imageURL})`);
          }
        }
      }
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.checkImageURL();
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/button/button.scss';
@import './columnBand.scss';
@import './masterHead.scss';
@import './masterHeadShort.scss';
</style>
