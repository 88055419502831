<script>
import CoreComponent from '@platform/dow-platform/components/core/CoreComponent.vue'
import {
  InlineVideoTracking,
  DiamondTVAnalytics,
  YouTubeAnalytics
} from './analytics'

export default {
  extends: CoreComponent,
  data() {
    return {
      allowedYouTubeHosts: [
        'www.youtube.com',
        'youtube.com',
        'www.youtu.be',
        'youtu.be'
      ]
    }
  },
  mounted() {
    const playButton = this.cmpEl.querySelector('.cmp-videoBand_playButton')
    const playIcon = this.cmpEl.querySelector('.cmp-videoBand_playIcon')
    const thumbnail = this.cmpEl.querySelector('.cmp-videoBand_thumbnail')
    const video = this.cmpEl.querySelector('video')
    const autoplay = this.cmpEl.getAttribute('data-autoplay')
    const loop = this.cmpEl.getAttribute('data-loop')
    const mute = this.cmpEl.getAttribute('data-mute')
    const controls = this.cmpEl.getAttribute('data-controls')
    let iframe = this.cmpEl.querySelector('iframe')
    let videoSrc = this.cmpEl.getAttribute('data-videoSrc')
    let isYouTube = false

    if (iframe) {
      videoSrc = iframe.src
      if (this.isYouTubeLink(videoSrc) && loop == 'true') {
        isYouTube = true
        iframe.src = this.createYouTubePlayListUrl(videoSrc)
        iframe.addEventListener(
          'load',
          () => new YouTubeAnalytics(this.$store, iframe.id)
        )
      } else if (!this.isYouTubeLink(videoSrc)) {
        isYouTube = false
        iframe.src = videoSrc
        iframe.addEventListener(
          'load',
          () => new DiamondTVAnalytics(iframe, this.$store, this.cmpId)
        )
      }
    }

    if (video) {
      if (autoplay == 'true') {
        video.setAttribute('autoplay', '')
        if (mute == 'true') {
          video.muted = true
          video.play()
        }
      } else if (mute == 'true') {
        video.muted = true
      }

      if (loop == 'true') {
        video.setAttribute('loop', '')
      }

      if (controls == 'true') {
        video.setAttribute('controls', '')
      }

      new InlineVideoTracking(video, this.$store, this.cmpId)
    }

    if (playButton) {
      playButton.addEventListener('click', () => {
        playIcon.classList.add('hide')
        thumbnail.classList.add('hide')
        if (iframe) {
          let tempSrc = this.playIframeVideo(videoSrc)
          if (isYouTube && loop == 'true') {
            tempSrc = this.createYouTubePlayListUrl(tempSrc)
          }
          iframe.src = tempSrc
          iframe.addEventListener('load', () => {
            isYouTube
              ? new YouTubeAnalytics(this.$store, iframe.id)
              : new DiamondTVAnalytics(iframe, this.$store, this.cmpId)
          })
        }
      })
    }
  },
  methods: {
    isYouTubeLink(url) {
      let self = this
      try {
        const parsedUrl = new URL(url)
        const domain = parsedUrl.hostname.toLowerCase()
        for (let i = 0; i < this.allowedYouTubeHosts.length; i++) {
          if (self.allowedYouTubeHosts[i].includes(domain)) {
            return true
          }
        }
      } catch (error) {
        return false
      }
    },
    playIframeVideo(url) {
      try {
        const videoUrl = new URL(url)
        const params = videoUrl.searchParams
        params.set('autoplay', '1')
        videoUrl.search = params.toString()
        return videoUrl.toString()
      } catch (error) {
        console.error('Error processing URL:', error)
        return videoUrl.origin + parsedUrl.pathname
      }
    },
    createYouTubePlayListUrl(url) {
      const regex = /(?:youtube\.com\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]+)/
      const match = url.match(regex)

      if (match && match[1]) {
        const videoID = match[1]
        const separator = url.includes('?') ? '&' : '?'
        return `${url}${separator}playlist=${videoID}&enablejsapi=1`
      } else {
        return url
      }
    }
  }
}
</script>

<style lang="scss">
@import './videoBand.scss';
</style>
