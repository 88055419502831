<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";
import DarkModeToggle from "../dark-mode-toggle/dark-mode-toggle.vue";

export default {
  extends: CoreComponent,

  data() {
    return {
      isOpen: false,
    }
  },

  mounted() {
    const navContainers = this.cmpEl.querySelectorAll('.cmp-globalheaderNavigationTray__nav-container')
    const primaryNavItem = this.cmpEl.querySelectorAll('.primaryNavItem')
    const mobileNav = this.cmpEl.querySelector('.mobileMenu')
    const secondaryNavItem = this.cmpEl.querySelectorAll('.cmp-newGlobalHeaderNavigationTray__item')
    const secondaryNavItemsWrappers = this.cmpEl.querySelectorAll('.cmp-newGlobalHeaderNavigationTray__items')
    const navigationTrayChildrens = this.cmpEl.querySelectorAll('.cmp-newGlobalHeaderNavigationTray__children')
    const mainMediaElements = this.cmpEl.querySelectorAll('.cmp-newGlobalHeaderNavigationTray__media')

    const updateMediaVisibility = () => {
      const anySecondaryChildrenOpen = Array.from(navigationTrayChildrens).some(
        (child) => child.classList.contains("secondary-children-open")
      );
      mainMediaElements.forEach((media) => {
        if (anySecondaryChildrenOpen) {
          media.style.display = "none"
        } else {
          media.style.display = ""
        }
      })
    }

    primaryNavItem.forEach((item, index) => {
      item.addEventListener('click', (event) => {

        primaryNavItem.forEach((item) => {
          if(item !== event.target){
            item.classList.remove('primaryNavItem-open')
          }
        })

        secondaryNavItem.forEach((secondaryItem) => {
          secondaryItem.classList.remove('cmp-newGlobalHeaderNavigationTray__item-not-open')
          secondaryItem.querySelector('button').classList.remove('cmp-newGlobalHeaderNavigationTray__item-open')
        })

        secondaryNavItemsWrappers.forEach((secondaryNavItemsWrapper) => {
          secondaryNavItemsWrapper.classList.remove('cmp-newGlobalHeaderNavigationTray__items-width')
          secondaryNavItemsWrapper.closest('.cmp-newGlobalHeaderNavigationTray__secondary').querySelector('.cmp-newGlobalHeaderNavigationTray__media').classList.remove('cmp-newGlobalHeaderNavigationTray__media-width')
        })

        navigationTrayChildrens.forEach((navigationTrayChildren) => {
          navigationTrayChildren.classList.remove('secondary-children-open')
        })

        event.target.classList.toggle('primaryNavItem-open')

        const navData = item.getAttribute('data-tray')
        navContainers.forEach((container, index) => {
          const containerData = container.getAttribute('data-tray')
          if (navData === containerData) {
            const containerChildren = container.querySelectorAll('.cmp-globalheaderNavigationTray__nav-container-supList__child')
            event.preventDefault();

            if (navContainers[index].classList.contains('active')) {
              navContainers[index].classList.remove('active')
              primaryNavItem[index].classList.remove('active-tab')
            } else {
              navContainers[index].classList.add('active')
              primaryNavItem[index].classList.add('active-tab')
            }
            if (containerChildren.length > 3) {
              navContainers[index].classList.add('align-col')
            } else {
              navContainers[index].classList.add('no-header')
            }
          } else {
            primaryNavItem[index].classList.remove('active-tab')
            navContainers[index].classList.remove('active')
          }
        })
        event.stopPropagation();
        updateMediaVisibility()
      })
    })
    const mobileButton = this.cmpEl.querySelector('.mobileNavButton')

    mobileButton.addEventListener('click', (event) => {
      if (mobileNav.classList.contains('openMobile')) {
        mobileNav.classList.remove('openMobile')
        
        secondaryNavItem.forEach((secondaryItem, index) => {
          secondaryItem.classList.remove('cmp-newGlobalHeaderNavigationTray__item-not-open')
          secondaryItem.closest('.cmp-newGlobalHeaderNavigationTray__items').querySelector('.cmp-newGlobalHeaderNavigationTray__main-item').classList.remove('cmp-newGlobalHeaderNavigationTray__main-item-open')
          secondaryItem.querySelector('button').classList.remove('cmp-newGlobalHeaderNavigationTray__item-open')
        })

        navigationTrayChildrens.forEach((navigationTrayChildren) => {
          navigationTrayChildren.classList.remove('secondary-children-open')
          })

        document
          .querySelectorAll('.cmp-newGlobalHeaderNavigationTray__secondary')
          .forEach((i) => {
            i.classList.remove('secondary-menu-open')
          })
      } else {
        mobileNav.classList.add('openMobile')
      }
      updateMediaVisibility()
    })

    // New header
    const newHeaderPrimaryLinks = this.cmpEl.querySelectorAll(
      'button.primaryNavItem'
    )
    newHeaderPrimaryLinks.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        const target = document.querySelector(item.dataset.target)
        document
          .querySelectorAll('.cmp-newGlobalHeaderNavigationTray__secondary')
          .forEach((i) => {
            if (i != target) {
              i.classList.remove('secondary-menu-open')
            }
          })
        if (target) {
          target.classList.toggle('secondary-menu-open')
          updateMediaVisibility()
        }
      })
    })
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        document
          .querySelectorAll('.cmp-newGlobalHeaderNavigationTray__secondary')
          .forEach((i) => {
            i.classList.remove('secondary-menu-open')
          })
          updateMediaVisibility()
      }
    })

    const newHeaderSecondaryLinks = this.cmpEl.querySelectorAll(
      '.cmp-newGlobalHeaderNavigationTray__item > button'
    )
    newHeaderSecondaryLinks.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()

        newHeaderSecondaryLinks.forEach((item, index) => {
           if(item !== e.target){
            item.classList.remove('cmp-newGlobalHeaderNavigationTray__item-open')
            item.closest('.cmp-newGlobalHeaderNavigationTray__item').classList.add('cmp-newGlobalHeaderNavigationTray__item-not-open')
            item.closest('.cmp-newGlobalHeaderNavigationTray__items').querySelector('.cmp-newGlobalHeaderNavigationTray__main-item').classList.remove('cmp-newGlobalHeaderNavigationTray__main-item-open')
          }
        })

        e.target.closest('.cmp-newGlobalHeaderNavigationTray__items').querySelector('.cmp-newGlobalHeaderNavigationTray__main-item').classList.toggle('cmp-newGlobalHeaderNavigationTray__main-item-open')
        e.target.classList.toggle('cmp-newGlobalHeaderNavigationTray__item-open')
        e.target.closest('.cmp-newGlobalHeaderNavigationTray__item').classList.remove('cmp-newGlobalHeaderNavigationTray__item-not-open')
        
        const children = item.parentNode.querySelector(
          '.cmp-newGlobalHeaderNavigationTray__children'
        )
        navigationTrayChildrens.forEach((navigationTrayChildren) => {
          if (navigationTrayChildren != children) {
            navigationTrayChildren.classList.remove('secondary-children-open')
          }
        })
        if (children) {
          children.classList.toggle('secondary-children-open')
          updateMediaVisibility()
        }
        if(children.classList.contains('secondary-children-open')) {
          e.target.closest('.cmp-newGlobalHeaderNavigationTray__items').classList.add('cmp-newGlobalHeaderNavigationTray__items-width')
          e.target.closest('.cmp-newGlobalHeaderNavigationTray__secondary').querySelector('.cmp-newGlobalHeaderNavigationTray__media').classList.add('cmp-newGlobalHeaderNavigationTray__media-width')
        } else {
          e.target.closest('.cmp-newGlobalHeaderNavigationTray__items').classList.remove('cmp-newGlobalHeaderNavigationTray__items-width')
          e.target.closest('.cmp-newGlobalHeaderNavigationTray__secondary').querySelector('.cmp-newGlobalHeaderNavigationTray__media').classList.remove('cmp-newGlobalHeaderNavigationTray__media-width')


        }
      })
    })

    //Back button mobile
    const backCta = this.cmpEl.querySelectorAll(
      '.cmp-newGlobalHeaderNavigationTray__level-1-button > button'
    );
    backCta.forEach((item) => {
      item.addEventListener('click', (e) => {

        newHeaderSecondaryLinks.forEach((secondaryItem, index) => {
          secondaryItem.closest('.cmp-newGlobalHeaderNavigationTray__item').classList.remove('cmp-newGlobalHeaderNavigationTray__item-not-open')
          secondaryItem.classList.remove('cmp-newGlobalHeaderNavigationTray__item-open')
        })

        item.closest('.cmp-newGlobalHeaderNavigationTray__items').querySelector('.cmp-newGlobalHeaderNavigationTray__main-item').classList.remove('cmp-newGlobalHeaderNavigationTray__main-item-open')        
        navigationTrayChildrens.forEach((navigationTrayChildren) => {
          navigationTrayChildren.classList.remove('secondary-children-open')
        })
        updateMediaVisibility()
      })
    })

    // Clone links from newGlobalNavigationBar
    const sourceDiv = document.querySelector(
      '.cmp-newGlobalheaderNavigationBar .cmp-global-header_links-wrapper'
    )
    const targetDiv = this.cmpEl.querySelector(
      '.cmp-newGlobalHeaderNavigationTray__links'
    )
    if (sourceDiv && targetDiv) {
      targetDiv.innerHTML += sourceDiv.innerHTML
    }
  }
}
</script>

<style lang="scss">
@import "./globalHeaderNavigationTray.scss";
@import "./newGlobalHeaderNavigationTray.scss";
</style>
