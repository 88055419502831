<!-- eslint-disable prettier/prettier -->
<script>
import PlatformButton from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/button/button.vue'

export default {
    extends: PlatformButton,

    mounted() {
        console.log("Testing");
    }
}
</script>

<style lang="scss">
@import '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/button/button.scss';
@import './button.scss';
</style>
