<script>
import CoreComponent from '../../core/CoreComponent.vue'

export default {
    extends: CoreComponent,
    data: function(){
        return {
            engineReady: Promise.resolve(false)
        }
    },
    computed: {
        searchEngine(){
            // let curatedSearchOptions = this.description.split(";");
            // this.mySelector = curatedSearchOptions[0].split("/").slice(-1).toString();
            return this.$store.state.search.engines[this.mySelector]?.engine;
          
        }
    },
    mounted(){
        if(this.type == "input"){
    
            this.engineReady = this.$store.dispatch("search/getEngine", { hub: this.mySelector });
        }
    }
}

</script>

<style lang="scss">

</style>